import React, { useEffect, useState, useCallback } from 'react'
import { useLocalStorage } from 'react-use';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Alert from '../../components/bootstrap/Alert';
import useDarkMode from '../../hooks/useDarkMode';
import Icon from '../../components/icon/Icon';
import Loader from '../Loader/Loader';
import './dashboard.css'

const Notifications =()=> {

    const [user, setUser, removeUser] = useLocalStorage('user', null);
	const navigate = useNavigate();
    const { baseUrl } = useDarkMode(); 
	const [loading, setLoading] = useState(true);
	const [notifications, setNotifications] = useState(true);

    const getNotes = useCallback(async () => {
		const providerData = {user_id: user.user_id, api_token: user.api_token}
		await axios.post(`${baseUrl}getLatestClientNotifications`, providerData )
		.then(res => {
			if (res.data.status === 'success') {
				// console.log(res.data?.data);
				setNotifications(res?.data?.data);
				setLoading(false);
			} else {
				// console.log(res.status);
                setNotifications([]);
				setLoading(false);
			}
		}).catch(function (error) {
            if (error.response) {
                // console.log(error.response)
                // setServerError(`Server status:${error.response.status} status`);
            }
        });
	}, [baseUrl, user]);

    useEffect(() => {
		if (localStorage.user === false || localStorage.user === undefined) {
			removeUser();
			setUser();
			navigate('/auth-pages/login');
		}
        if (!user) {
			removeUser();
			navigate('/auth-pages/login');
		}
        getNotes();
        return () => { };
	}, [user,setUser, removeUser, navigate, getNotes]);

  return (
    <div>
        {loading &&
            <Loader />
        }

        {
            notifications !== undefined && notifications.length > 0 ?
            <div>{notifications?.map(p=> <div key={p.id}>
                {
                    p.type === 1 || p.type === "1" ? 
                    <Alert icon='Public' isLight color='success' className='flex-nowrap'>
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div style={{fontWeight:600, fontSize:12}}>{moment(p.created_at).fromNow()}</div>
                    </Alert>
                    : null
                }
                {
                    p.type === 2 || p.type === "2" ? 
                    <Alert icon='LocalOffer' isLight color='success' className='flex-nowrap'>
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div style={{fontWeight:600, fontSize:12}}>{moment(p.created_at).fromNow()}</div>
                    </Alert>
                    : null
                }
                {
                    p.type === 3 || p.type === "3" ? 
                    <Alert icon='Campaign' isLight color='secondary' className='flex-nowrap'>
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div style={{fontWeight:600, fontSize:12}}>{moment(p.created_at).fromNow()}</div>
                    </Alert>
                    : null
                }
                {
                    p.type === 4 || p.type === "4" ? 
                    <Alert icon='Campaign' isLight color='dark' className='flex-nowrap'>
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div style={{fontWeight:600, fontSize:12}}>{moment(p.created_at).fromNow()}</div>
                    </Alert>
                    : null
                }
                {
                    p.type === 5 || p.type === "5" ? 
                    <Alert icon='AccountBalanceWallet' isLight color='danger' className='flex-nowrap'>
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div style={{fontWeight:600, fontSize:12}}>{moment(p.created_at).fromNow()}</div>
                    </Alert>
                    : null
                }
                {
                    p.type === 6 || p.type === "6" ? 
                    <Alert icon='MobileFriendly' isLight color='info' className='flex-nowrap'>
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div style={{fontWeight:600, fontSize:12}}>{moment(p.created_at).fromNow()}</div>
                    </Alert>
                    : null
                }
                {
                    p.type === 7 || p.type === "7" ? 
                    <Alert icon='Public' isLight color='warning' className='flex-nowrap'>
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div style={{fontWeight:600, fontSize:12}}>{moment(p.created_at).fromNow()}</div>
                    </Alert>
                    : null
                }
                {
                    p.type === 8 || p.type === "8" ? 
                    <Alert icon='LocalOffer' isLight color='danger' className='flex-nowrap'>
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div style={{fontWeight:600, fontSize:12}}>{moment(p.created_at).fromNow()}</div>
                    </Alert>
                    : null
                }
            </div>)}</div>
            : <div className='ra-no-data'><Icon icon='Notes' size='4x' color='grey' /> No Data Available</div>
        }        
    </div>
  )

}

export default Notifications;