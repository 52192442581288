import { useContext } from 'react';
import ThemeContext from '../contexts/themeContext';

export default function useDarkMode() {
	const { darkModeStatus, setDarkModeStatus } = useContext(ThemeContext);

	const themeStatus = darkModeStatus ? 'dark' : 'light';
	// const baseUrl = 'http://sms9.live/api/';
	// const serverUrl = 'http://sms9.live/';
	// const domainUrl = 'http://localhost:3000/';

	const baseUrl = 'https://azeem.dev.jt.hostingshouse.com/projects/smsplus-react/api/';
	const serverUrl = 'https://azeem.dev.jt.hostingshouse.com/projects/smsplus-react/';
	const domainUrl = 'https://sms.shahbaz.dev.jt.hostingshouse.com/';

	return { themeStatus, darkModeStatus, setDarkModeStatus,baseUrl, serverUrl, domainUrl};
}