import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import axios from 'axios';
import { useLocalStorage } from 'react-use';
import USERS from '../../common/data/userDummyData';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import showNotification from '../../components/extras/showNotification';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import { extraMenu } from '../../menu';

const User = () => {
	const navigate = useNavigate();
	const [user, setUser, removeUser] = useLocalStorage('user', null);
	
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus,serverUrl, baseUrl } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState(false);
	const [serverError, setServerError] = useState("");
	const { t } = useTranslation(['translation', 'menu']);

	useEffect(() => {
		if (localStorage.user === false || localStorage.user === undefined) {
			removeUser();
			setUser();
			navigate('/auth-pages/login');
		}
        if (!user) {
			removeUser();
			navigate('/auth-pages/login');
		}
	}, [user,setUser, removeUser, navigate]);

	const logout = () => {
		const paramsLogout = { user_id: user?.user_id, api_token: user?.api_token };
        axios.post(`${baseUrl}clientLogoutActivity`, paramsLogout, { 
        })
        .then(dataRes => { // then print response status
            if (dataRes.data.status === 'success') {
                showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>User Logout: </span>
					</span>,
					dataRes.data.message,
				);
            } else {
                showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>User Logout: </span>
					</span>,
					dataRes.data.message,
				);
            }
        }).catch(function (error) {
            if (error.response) {
				console.log(serverError)
                setServerError(`Server status:${error.response.status} status`);
            }
        });
		removeUser();
        navigate('/auth-pages/login');
    };

	const userProfile =()=> {
		navigate(extraMenu.editProfile.path);
	}
	const accountSettings =()=> {
		navigate(extraMenu.accountSettings.path);
	}

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}
			>
				{
					user?.photo !== undefined && user?.photo !== null ?
					<div className='user-avatar'>
						<img
							srcSet={`${serverUrl}storage/users/${user?.user_id}/profile/${user?.photo}`}
							src={`${serverUrl}storage/users/${user?.user_id}/profile/${user?.photo}`}
							alt='Avatar'
							width={128}
							height={128}
						/>
					</div>
					: <div className='user-avatar'>
						<img
							srcSet={USERS.JOHN.srcSet}
							src={USERS.JOHN.srcSet}
							alt='Avatar'
							width={128}
							height={128}
						/>
					</div>
				}
				<div className='user-info'>
					<div className='user-name d-flex align-items-center'>
						{user?.first_name !== null ? `${user?.first_name} ${user?.last_name}` : user?.name}
						<Icon icon='Verified' className='ms-1' color='info' />
					</div>
					<div className='user-sub-title'>{user?.posistion}</div>
				</div>
			</div>
			<DropdownMenu>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode---'}
					</Button>
				</DropdownItem>
			</DropdownMenu>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={userProfile}
							>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon="ManageAccounts"
										color="secondary"
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										Edit Profile
									</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={accountSettings}
							>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon="Settings"
										color="info"
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										Account Settings
									</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer d-none'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus ? t('menu:DarkMode') : t('menu:LightMode')}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={logout}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Logout')}</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
