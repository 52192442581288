import React, { useEffect, useState } from 'react';
import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import showNotification from '../../../components/extras/showNotification';

const CommonHeaderChat = () => {
	const [state, setState] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			showNotification(
				<span className='d-flex align-items-center'>
					<Avatar
						srcSet={USERS.CHLOE.srcSet}
						src={USERS.CHLOE.src}
						size={36}
						color={USERS.CHLOE.color}
						className='me-3'
					/>
					<span>{USERS.CHLOE.name} sent a message.</span>
				</span>,
				<div onClick={() => setState(!state)} role='presentation'>
					<p>I think it's really starting to shine.</p>
				</div>,
			);
		}, 30000);
		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
		</>
	);
};

export default CommonHeaderChat;
