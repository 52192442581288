export const styles = {
	/**
	 * Overlay style
	 * @param base
	 */
	maskWrapper: (base) => ({ ...base }),
	maskArea: (base) => ({
		...base,
		rx: 'var(--bs-border-radius)',
	}),
	highlightedArea: (base) => ({ ...base }),
	badge: (base) => ({ ...base }),
	popover: (base) => ({
		...base,
		boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)',
		backgroundColor: 'var(--bs-body-bg)',
		color: 'var(--bs-body-color)',
		borderRadius: 'var(--bs-border-radius)',
	}),
};

const steps = [];

export default steps;
