import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Loader from '../Loader/Loader';
import LogoLogin from '../../components/LogoLogin';
import Icon from '../../components/icon/Icon';
import showNotification from '../../components/extras/showNotification';
import useDarkMode from '../../hooks/useDarkMode';

const LoginHeader = () => {
	return (
		<>
			<div className='text-center h4 fw-bold mt-5'>Welcome to MumaraSMS</div>
			<div className='text-center h4 text-muted mb-1'>Reset Your Password</div>
		</>
	);
};

const ResetPassword = () => {

	useTitle('Reset Password | SMS');
	const { token } = useParams();
	const navigate = useNavigate();
	const { baseUrl } = useDarkMode();
	const [loading, setLoading] = useState(true);
    const [npassword, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
	const [serverError, setServerError] = useState("");
	const [passError, setPassError] = useState("");
	const [passError2, setPassError2] = useState("");

	const handlePassswordChange = (e) => {
		setPassword(e.target.value);
	}
    const handleConfirmPassswordChange = (e) => {
		setConfirmPassword(e.target.value);
	}

	useEffect(() => {
		setLoading(false)
		// return () => {};
	}, []);

    const handleOnClick = async () => {
        setPassError("");
        if(npassword === "") {
            setPassError("Password is required");
        } else if (npassword.length < 8) {
            setPassError("Password must be at least 8 characters");
        } else if(confirmPassword === "") {
            setPassError("");
            setPassError2("Confirm password is required")
        } else if(confirmPassword !== npassword) {
            setPassError("");
            setPassError2("Password not match")
        } else {
            setLoading(true);
            setPassError2("");
            const emailToken = token;
            const newPasswordData = {password: npassword, token: emailToken}
            axios.post(`${baseUrl}resetPassword`, newPasswordData )
            .then(res => {
                if(res?.data?.status === 'success') {
                    setLoading(false)
                    showNotification(
                        <span className='d-flex align-items-center'>
                            <Icon icon='Info' size='lg' className='me-1' />
                            <span>Forgot Password</span>
                        </span>,
                        res?.data?.message,
                    );
                    setPassword("");
                    setConfirmPassword("")
                    navigate('/auth-pages/login');
                } else {
                    showNotification(
                        <span className='d-flex align-items-center'>
                            <Icon icon='Info' size='lg' className='me-1' />
                            <span>Forgot Password</span>
                        </span>,
                        res?.data?.message,
                    );
                    setLoading(false)
                }
            }).catch(function (error) {
                setLoading(false)
                if (error.response) {
                    setServerError(`Server status:${error.response.status} status`);
                }
            });
        }
	}
	
	return (
		<PageWrapper
			title="Reset Password"
			className="bg-success">
			<Page className='p-0'>
				{loading &&
					<Loader />
				}
				<div className='row h-100 align-items-center justify-content-center'>
					
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>

                                <div className='text-center my-2'>
                                    <Link to='/' >
                                        <LogoLogin width={200} />
                                    </Link>
                                </div>

                                <LoginHeader />
			                    <div className='text-center text-muted mb-5'>Your identity has been verified!<br />Set your new password.</div>
                                {serverError !== "" ? <div className="alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center" >
                                    <span className="text-white">{serverError}</span>
                                </div> : ""}
								<form className='row g-4'>
                                    <div className='col-12'>
                                        <FormGroup
                                            id='password'
                                            isFloating
                                            onChange={handlePassswordChange}
                                            label='New Password'>
                                            <Input
                                                type='password'
                                                autoComplete='password'
                                                name="password"

                                            />
                                        </FormGroup>
                                        <span className='text-danger'>{passError}</span>
                                    </div>
                                    <div className='col-12'>
                                        <FormGroup
                                            id='confirm-password'
                                            isFloating
                                            onChange={handleConfirmPassswordChange}
                                            label='Confirm Password'>
                                            <Input
                                                type='password'
                                                autoComplete='password'
                                                name="confirm-password"

                                            />
                                        </FormGroup>
                                        <span className='text-danger'>{passError2}</span>
                                    </div>
                                    <div className='col-12'>
                                        <Button
                                            color='success'
                                            className='w-100 py-3'
                                            onClick={handleOnClick}
                                        >
                                            Reset Password
                                        </Button>
                                    </div>
                                </form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Privacy policy</Link>
							<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Terms of use</Link>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ResetPassword;