import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, height }) => {
	return (
		<img 
			src='/logo-light.png'
			alt='Mumara'
			width={height !== 832 ? height * (3227 / 832) : width}
			height={width !== 3227 ? width * (832 / 3227) : height}
		/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 3227,
	height: 832,
};

export default Logo;
