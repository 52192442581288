import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

const LogOut = () => {

	const navigate = useNavigate();
	const [user, setUser, removeUser] = useLocalStorage('user', null);

    useEffect(() => {
		if (localStorage.user === false || localStorage.user === undefined) {
			removeUser();
			setUser();
			navigate('/auth-pages/login');
		}
        if (!user) {
			removeUser();
			navigate('/auth-pages/login');
		}

        navigate('/auth-pages/login');
	}, [user,setUser, removeUser, navigate]);

  return (
    <div>logOut</div>
  )
}

export default LogOut;