import React, {useEffect, useState, useCallback} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import Icon from '../../components/icon/Icon';
import Loader from '../Loader/Loader';
import useDarkMode from '../../hooks/useDarkMode';

const Confirmation = () => {
	const navigate = useNavigate();
	const { baseUrl } = useDarkMode();
	const { id } = useParams();
	const [data, setData] = useState([]);
	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchUser();
		// return () => {};
	}, [fetchUser]);
	const fetchUser = useCallback(async () => {
		setServerError("")
		const param = { user_id: id };
		axios.post(`${baseUrl}getUserName`, param, { // receive two parameter endpoint url ,form data 
		}).then(dataRes => { // then print response status
			// console.log(dataRes?.data)
			if (dataRes?.data?.status === "success") {
				setData(dataRes?.data?.data);
				setLoading(false);
			} else if(dataRes?.data?.status === "record_not_exist") {
				setData({...data, status: 'unavailable', first_name: 'Guest', last_name: ''});
				setLoading(false);
			} else {
				setData([]);
				setLoading(false);
			}
		}).catch(function (error) {
			setLoading(false);
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
		})
	}, [baseUrl, data, id]);
	const loginPage =()=> {
		navigate('/auth-pages/login');
	}
	
	return (
		<PageWrapper
			title="Login"
			className="bg-success">
			<Page className='p-0'>
				{loading &&
					<Loader />
				}
				<div className='row h-100 align-items-center justify-content-center'>
					{data !== undefined && data?.status === 'active' ?
						<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
							<Card className='shadow-3d-dark' data-tour='login-page'>
								<CardBody>
									{/* <div className='text-center my-1 mb-0 mt-3'>
										<Link to={`/confirmation/${id}`} >
											<LogoLogin width={200} />
										</Link>
									</div> */}
									{serverError !== "" ? <div className="alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center" >
										<span className="text-white">{serverError}</span>
									</div> : ""}
									<div className='text-center my-1 mb-0 mt-3'>
										<Icon icon='CheckCircle' color='success' style={{width: 100, height:100}} />
									</div>
									<div className='text-center h4 fw-bold mt-4'>{data !== undefined && data?.first_name ? <span className='h3 fw-bold'>Hello <span className='text-info'>{data?.first_name} {data?.last_name}</span> <br /></span>: ""} Welcome to MumaraSMS</div>
									<div className='text-center h4 text-muted mb-2'>Your email has been verified!</div>
									<div className='text-center h6 text-muted mb-3'>You can now login to continue sms services.</div>
									<div className='col-12 text-center mb-0 '>
										<Button
											color='success'
											className='w-100 py-3'
											onClick={loginPage}
										>
											Go to Login Page
										</Button>
									</div>
								</CardBody>
							</Card>
							<div className='text-center'>
								<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Privacy policy</Link>
								<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Terms of use</Link>
							</div>
						</div>
					: null }
					{data !== undefined && data?.status === 'unverified' ?
						<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
							<Card className='shadow-3d-dark' data-tour='login-page'>
								<CardBody>
									{serverError !== "" ? <div className="alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center" >
										<span className="text-white">{serverError}</span>
									</div> : ""}
								
									<div className='text-center my-1 mb-0 mt-3'>
										<Icon icon='MarkEmailUnread' color='success' style={{width: 100, height:100}} />
									</div>
									
									<div className='text-center h4 fw-bold mt-4'>{data !== undefined && data?.first_name ? <span className='h3 fw-bold'>Hello <span className='text-info'>{data?.first_name} {data?.last_name}</span> <br /></span>: ""} Welcome to MumaraSMS</div>
									<div className='text-center h4 text-muted mb-2'>Your email is unverified!</div>
									<div className='text-center h6 text-muted mb-4'>Please check your email to verify your account.</div>
								</CardBody>
							</Card>
							<div className='text-center'>
								<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Privacy policy</Link>
								<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Terms of use</Link>
							</div>
						</div>
					: null }
					{data !== undefined && data?.status === 'inactive' ?
						<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
							<Card className='shadow-3d-dark' data-tour='login-page'>
								<CardBody>
									{serverError !== "" ? <div className="alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center" >
										<span className="text-white">{serverError}</span>
									</div> : ""}
								
									<div className='text-center my-1 mb-0 mt-3'>
										<Icon icon='SentimentDissatisfied' color='warning' style={{width: 100, height:100}} />
									</div>
									
									<div className='text-center h4 fw-bold mt-4'>{data !== undefined && data?.first_name ? <span className='h3 fw-bold'>Hello <span className='text-info'>{data?.first_name} {data?.last_name}</span> <br /></span>: ""} Welcome to MumaraSMS</div>
									<div className='text-center h4 text-muted mb-2'>Your email has been deactivated!</div>
									<div className='text-center h6 text-muted mb-4'>Please Contact to administrator.</div>
								</CardBody>
							</Card>
							<div className='text-center'>
								<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Privacy policy</Link>
								<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Terms of use</Link>
							</div>
						</div>
					: null }
					{data !== undefined && data?.status === 'closed' ?
						<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
							<Card className='shadow-3d-dark' data-tour='login-page'>
								<CardBody>
									{serverError !== "" ? <div className="alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center" >
										<span className="text-white">{serverError}</span>
									</div> : ""}
								
									<div className='text-center my-1 mb-0 mt-3'>
										<Icon icon='Error' color='danger' style={{width: 100, height:100}} />
									</div>
									<div className='text-center h4 fw-bold mt-4'>{data !== undefined && data?.first_name ? <span className='h3 fw-bold'>Hello <span className='text-info'>{data?.first_name} {data?.last_name}</span> <br /></span>: ""} Welcome to MumaraSMS</div>
									<div className='text-center h4 text-muted mb-2'>Your email has been Suspended!</div>
									<div className='text-center h6 text-muted mb-4'>Please Contact to administrator.</div>
								</CardBody>
							</Card>
							<div className='text-center'>
								<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Privacy policy</Link>
								<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Terms of use</Link>
							</div>
						</div>
					: null }
					{data !== undefined && data?.status === 'unavailable' ?
						<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
							<Card className='shadow-3d-dark' data-tour='login-page'>
								<CardBody>
									{serverError !== "" ? <div className="alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center" >
										<span className="text-white">{serverError}</span>
									</div> : ""}
									<div className='text-center my-1 mb-0 mt-3'>
										<Icon icon='Error' color='danger' style={{width: 100, height:100}} />
									</div>
									<div className='text-center h4 fw-bold mt-4'>{data !== undefined && data?.first_name ? <span className='h3 fw-bold'>Hello <span className='text-info'>Guest</span> <br /></span>: ""} Welcome to MumaraSMS</div>
									<div className='text-center h4 text-muted mb-2'>This Account is not Exist</div>
									<div className='text-center h6 text-muted mb-4'>Please Contact to administrator.</div>
								</CardBody>
							</Card>
							<div className='text-center'>
								<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Privacy policy</Link>
								<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Terms of use</Link>
							</div>
						</div>
					: null }
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Confirmation;