import React, { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import { useLocalStorage } from 'react-use';
import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert';
import PhoneInput from 'react-phone-number-input'
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Button from '../../components/bootstrap/Button';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Select from '../../components/bootstrap/forms/Select';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import validate from './helper/editPagesValidate';
// import editPasswordValidate from './helper/editPasswordValidate';
// import validateAddress from './helper/editPageAddressValidate';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import USERS from '../../common/data/userDummyData';
import Icon from '../../components/icon/Icon';
import PaginationButtons, {
	PER_COUNT,
} from '../../components/PaginationButtons';
import { extraMenu } from '../../menu';
import showNotification from '../../components/extras/showNotification';
import useDarkMode from '../../hooks/useDarkMode';
import Loader from '../Loader/Loader';
import '../contacts/addcontacts.css';

const TimeZone = [
	{ value: '-12:00', text: '(GMT -12:00) Eniwetok, Kwajalein' },
	{ value: '-11:00', text: '(GMT -11:00) Midway Island, Samoa' },
	{ value: '-10:00', text: '(GMT -10:00) Hawaii' },
	{ value: '-09:00', text: '(GMT -9:00) Alaska' },
	{ value: '-08:00', text: '(GMT -8:00) Pacific Time (US &amp; Canada)' },
	{ value: '-07:00', text: '(GMT -7:00) Mountain Time (US &amp; Canada)' },
	{ value: '-06:00', text: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City' },
	{ value: '-05:00', text: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima' },
	{ value: '-04:00', text: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz' },
	{ value: '-03:30', text: '(GMT -3:30) Newfoundland' },
	{ value: '-03:00', text: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
	{ value: '-02:00', text: '(GMT -2:00) Mid-Atlantic' },
	{ value: '-01:00', text: '(GMT -1:00 hour) Azores, Cape Verde Islands' },
	{ value: '+00:00', text: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
	{ value: '+01:00', text: '(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris' },
	{ value: '+02:00', text: '(GMT +2:00) Kaliningrad, South Africa' },
	{ value: '+03:00', text: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
	{ value: '+03:30', text: '(GMT +3:30) Tehran' },
	{ value: '+04:00', text: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
	{ value: '+04:30', text: '(GMT +4:30) Kabul' },
	{ value: '+05:00', text: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
	{ value: '+05:30', text: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
	{ value: '+06:00', text: '(GMT +6:00) Almaty, Dhaka, Colombo' },
	{ value: '+07:00', text: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
	{ value: '+08:00', text: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
	{ value: '+09:00', text: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
	{ value: '+09:30', text: '(GMT +9:30) Adelaide, (GMT +9:30) Adelaide, Darwin' },
	{ value: '+10:00', text: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
	{ value: '+11:00', text: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
	{ value: '+12:00', text: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
];

const EditProfile = () => {
    
	const [user, setUser] = useLocalStorage('user', null);
	const {baseUrl, serverUrl} = useDarkMode();
	const [userData, setUserData] = useState([]);
	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(true);
	const [countriesData, setCountriesData] = useState(true);
	const [value, setValue] = useState();
	const [vcode, setVcode] = useState("");
	const [sendCode, setSendCode] = useState(false);
    const [fileData, setFileData] = useState("");
    const [docData, setDocData] = useState("");
    const [avatar, setAvatar] = useState("");
	const [opassword, setOpassword] = useState("");
	const [npassword, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [opError, setOpError] = useState("");
    const [npError, setNpError] = useState("");
    const [rpError, setRpError] = useState("");
    const [opMsg, setOpMsg] = useState("");
    const [npMsg, setNpMsg] = useState("");
    const [rpMsg, setRpMsg] = useState("");
    const [path, setPath] = useState("");
	const [pagination, setPagination] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const [documents, setDocuments] = useState([]);
	const [time, setTime] = useState([]);
	
    const TABS = {
		EDIT_PROFILE: 'Edit Profile',
		SETTING: 'Change Mobile No',
		AVATAR: 'Change Profile Photo',
		PASSWORD: 'Change Password',
		DOCUMENT: 'Documents',
	};
	const [activeTab, setActiveTab] = useState(TABS.EDIT_PROFILE);

	useEffect(() => {
		fetchUser();
		getCountries();
		getDocs();
		// return () => {};
	}, [fetchUser, getCountries, getDocs]);

    const fetchUser = useCallback(async () => {
		setServerError("")
		const param = { user_id: user?.id, api_token: user?.token};
		axios.post(`${baseUrl}getClientDetail`, param, { // receive two parameter endpoint url ,form data 
		}).then(dataRes => { // then print response status
			if (dataRes?.data?.status === "success") {
				setUser(dataRes?.data?.data);
				setUserData(dataRes?.data?.data);
				setAvatar(`${serverUrl}storage/users/${user?.user_id}/profile/${dataRes?.data?.data?.photo}`)
				setLoading(false);
				setTime(dataRes?.data?.data?.time_zone);		
				// console.log(dataRes?.data?.data?.time_zone);		
			} else {
				setUserData([]);
				setLoading(false);
			}
		}).catch(function (error) {
			setLoading(false);
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
		})
	}, [baseUrl, user, serverUrl, setUser]);

    const getCountries = useCallback(async () => {
		setServerError("")
		const param = { user_id: user?.id, api_token: user?.token};
		axios.post(`${baseUrl}getCountries`, param, { // receive two parameter endpoint url ,form data 
		}).then(dataRes => { // then print response status
			// console.log(dataRes?.data)
			if (dataRes?.data?.status === "success") {
				setCountriesData(dataRes?.data?.data);
				setLoading(false);
			} else {
				setCountriesData([]);
				setLoading(false);
			}
		}).catch(function (error) {
			setLoading(false);
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
		})
	}, [baseUrl, user]);

	const formik = useFormik({
		initialValues: {
			firstName: user.first_name,
			lastName: user?.last_name ,
			companyName: user?.company_name,
			email:user?.email,
			country: user?.country,
			mobileNo: user?.mobile_no,
			phone: user?.phone,
			status: user?.status,
			timeZone: user?.time_zone,
			jobTitle: user?.job_title,
			address: user?.address,
			address2: user?.address2,
			city: user?.city,
			state: user?.state,
			zip: user?.zip,
			website: user?.website,
			skype: user?.skype,
			facebook: user?.facebook,
			linkedin: user?.linkedin,
			twitter: user?.twitter,
		},
		validate,
		onSubmit: () => {
			setLoading(true);
			const params = { 
				user_id: user.user_id, 
				api_token: user.api_token, 
				first_name:formik.values.firstName,
				last_name:formik.values.lastName,
				email:formik.values.email,
				phone:formik.values.phone,
				country:formik.values.country,
				time_zone:time,
				city:formik.values.city,
				state:formik.values.state,
				zip:formik.values.zip,
				address:formik.values.address,
				address2:formik.values.address2,
				company_name:formik.values.companyName,
				job_title:formik.values.jobTitle,
				website:formik.values.website,
				skype:formik.values.skype,
				facebook:formik.values.facebook,
				linkedin:formik.values.linkedin,
				twitter:formik.values.twitter,
			};
			axios.post(`${baseUrl}profileUpdate`, params, { 
			})
			.then(dataRes => { 
				if(dataRes?.data?.status==="success"){
					setUser(dataRes?.data?.data);
					setUserData(dataRes?.data?.data);
					setLoading(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Profile Updated Successfully</span>
						</span>,
						dataRes?.data?.message,
					);
					setTime(dataRes?.data?.data?.time_zone);
				} else if (dataRes?.data?.status==="false" && dataRes?.data?.code===700) {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Session Out</span>
						</span>,
						dataRes?.data?.message,
					);
					setLoading(false);
				}
				else {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Profile Updated error!</span>
						</span>,
						dataRes?.data?.message,
					);
					setLoading(false);
				}
				setLoading(false);
			});
		},
	});

	const formikMobile = useFormik({
		initialValues: {
			mobile_no: value,
		},
		onSubmit: () => {
			// console.log(value)
			if(value === undefined || value === "") {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Mobile No Empty</span>
					</span>,
					"Please enter new mobile no!",
				);
			} else {
				setLoading(true);
				const params2 = { 
					user_id: user?.user_id, 
					api_token: user?.api_token, 
					mobile_no:value,
					tempMobile:1,
				};
				axios.post(`${baseUrl}sendMobileVerificationCode`, params2, { 
				})
				.then(dataRes => { 
					if(dataRes?.data?.status==="success"){
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Mobile No Change</span>
							</span>,
							dataRes?.data?.message,
						);
						fetchUser();
						setSendCode(true)
						setLoading(false);
					} else {
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Mobile No Change error!</span>
							</span>,
							dataRes?.data?.message,
						);
						setSendCode(false)
						setLoading(false);
					}
					setLoading(false);
				});
			}
		},
	});

	const formikMobileVerify = useFormik({
		initialValues: {
			code: '',
		},
		onSubmit: () => {
			// console.log(vcode.target.value)
			const codeData = vcode.target.value;
			if(codeData === undefined || codeData === "") {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Mobile Code Empty</span>
					</span>,
					"Please enter mobile code",
				);
			} else {
				setLoading(true);
				const params3 = { 
					user_id: user?.user_id, 
					api_token: user?.api_token, 
					code:codeData,
				};
				axios.post(`${baseUrl}verifyMobileVerificationCode`, params3, { 
				})
				.then(dataRes => { 
					if(dataRes?.data?.status==="success"){
						setLoading(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Mobile No Change</span>
							</span>,
							dataRes?.data?.message,
						);
						setSendCode(false)
						setValue("")
					} else {
						setLoading(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Mobile code error!</span>
							</span>,
							"Your code is not valid, please check your mobile and try again.",
						);
						setSendCode(true)
					}
					setLoading(false);
				}).catch(function (error) {
					setLoading(false);
					setSendCode(true)
					if (error.response) {
						setServerError(`Server status:${error.response.status} status`);
					}
				});
			}
		},
	});

	function calculate2(val) {
		const num = parseFloat(val); // Read Value from DOM
		const rounded = num.toFixed(2); // Round Number
		return Number(rounded);
	}
	const onFileChange = (event) => {
        setFileData(event.target.files[0]);
        // console.log(fileData);
    };
	const uploadPhoto =()=> {

		// console.log(fileData);
		setLoading(true);

		const data = new FormData()
		data.append('user_id',user.user_id);
        data.append('api_token', user.api_token);
		data.append('file', fileData);

		axios.post(`${baseUrl}changeProfilePhoto`, data, { 
        }).then(dataRes => { 
            if(dataRes.data.status==='success'){
                showNotification(
                    <span className='d-flex align-items-center'>
                        <Icon icon='Info' size='lg' className='me-1' />
                        <span>Profile Photo Change</span>
                    </span>,
                    dataRes.data.message,
                );
				setLoading(false);
				setAvatar(`${serverUrl}storage/users/${user?.user_id}/profile/${dataRes?.data?.data?.photo}`)
            }else{
                showNotification(
                    <span className='d-flex align-items-center'>
                        <Icon icon='Info' size='lg' className='me-1' />
                        <span>Profile Photo Change Error!</span>
                    </span>,
                    dataRes.data.message,
                );
				setLoading(false);
            }
			setLoading(false);
        })
	}
	const handleOpassswordChange = (e) => {
		setOpassword(e.target.value);
		// console.log(e.target.value)
	}
	const handlePassswordChange = (e) => {
		setPassword(e.target.value);
		// console.log(e.target.value)
	}
    const handleConfirmPassswordChange = (e) => {
		setConfirmPassword(e.target.value);
		// console.log(e.target.value)
	}
	const resetPasswordFrom =()=> {
		setOpError("");
		setNpError("");
		setRpError("");
		setOpMsg("");
		setNpMsg("");
		setRpMsg("");
		setOpassword("");
		setPassword("");
		setConfirmPassword("");
	}
	const passwordReset =()=> {
		if(opassword === ""){
			setOpError("is-invalid");
			setOpMsg("Please provide old password.");
		} else if(npassword === "") {
			setOpError("is-valid");
			setOpMsg("");
			setNpError("is-invalid");
			setNpMsg("Password is required");
		} else if (npassword.length < 6) {
			setOpError("is-valid");
			setOpMsg("");
			setNpError("is-invalid");
			setNpMsg("Password must be at least 6 characters");
		} else if (confirmPassword === "") {
			setOpError("is-valid");
			setOpMsg("");
			setNpError("is-valid");
			setNpMsg("");
			setRpError("is-invalid");
			setRpMsg("Password is required");
		} else if(npassword !== confirmPassword) {
			setOpError("is-valid");
			setOpMsg("");
			setNpError("is-valid");
			setNpMsg("");
			setRpError("is-invalid");
			setRpMsg("Password not match");
		} else {
			setOpError("is-valid");
			setOpMsg("");
			setNpError("is-valid");
			setNpMsg("");
			setRpError("is-valid");
			setRpMsg("");
			setLoading(true)
			const passParams = { 
				user_id: user?.user_id, 
				api_token: user?.api_token, 
				old_password:opassword,
				password:npassword,
				confirm_password:confirmPassword,
			};
			axios.post(`${baseUrl}changePassword`, passParams, { 
			})
			.then(dataRes => { 
				if(dataRes?.data?.status==="success"){
					setLoading(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Password change</span>
						</span>,
						dataRes?.data?.message,
					);
					setOpError("");
					setNpError("");
					setRpError("");
					setOpMsg("");
					setNpMsg("");
					setRpMsg("");
					setOpassword("");
					setPassword("");
					setConfirmPassword("");
				} else {
					setLoading(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Password change error!</span>
						</span>,
						"Your code is not valid, please check your mobile and try again.",
					);
				}
				setLoading(false);
			}).catch(function (error) {
				setLoading(false);
				if (error.response) {
					setServerError(`Server status:${error.response.status} status`);
				}
			});
		}
	}
	const onDocChange = (event) => {
        setDocData(event.target.files[0]);
        // console.log(event.target.files[0]);
    };
	const uploadDocument =()=> {
		// console.log(docData);
		setLoading(true);
		const data = new FormData()
		data.append('user_id',user.user_id);
        data.append('api_token', user.api_token);
		data.append('document', docData);

		axios.post(`${baseUrl}uploadUserDocument`, data, { 
        }).then(dataRes => { 
			getDocs();
			// console.log(dataRes.data);
            if(dataRes.data.status==='success'){
                showNotification(
                    <span className='d-flex align-items-center'>
                        <Icon icon='Info' size='lg' className='me-1' />
                        <span>Documentt Uploaded {dataRes.data.file_name}</span>
                    </span>,
                    dataRes.data.message,
                );
				setLoading(false);
				setDocData("")
            }else{
                showNotification(
                    <span className='d-flex align-items-center'>
                        <Icon icon='Info' size='lg' className='me-1' />
                        <span>Profile Photo Change Error!</span>
                    </span>,
                    dataRes.data.message,
                );
				setLoading(false);
            }
			setLoading(false);
        }).catch(function (error) {
			setLoading(false);
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
		});
	}

	const getDocs = useCallback(async () => {
		const documentsParams = {
			user_id: user.user_id, api_token: user.api_token, page:currentPage, items_per_page:perPage
		}
		axios.post(`${baseUrl}getUserDocuments`, documentsParams )
		.then(res => {
			if (res?.data?.status === 'success') {
				// console.log(res);
				setDocuments(res?.data?.data);
				setPagination(res?.data?.payload?.pagination);
				setLoading(false);
				setCurrentPage(res?.data.payload?.pagination?.page);
				setPath(res?.data?.downloadPath);
			} else {
				// console.log(res.status);
				setLoading(false);
			}
		}).catch(function (error) {
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
		})
	}, [user,baseUrl, currentPage, perPage]);

	const deleteDocument = async (id) => {

		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this contact",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  })
		  .then((willDelete) => {
			if (willDelete) {
				const datacontact = new FormData() 
				datacontact.append('document_id', id)
				datacontact.append('user_id',user.user_id);
				datacontact.append('api_token', user.api_token);
				axios.post(`${baseUrl}deleteDocument`, datacontact, { 
				}).then(dataRes => {
					if (dataRes.data.status === 'success') {
						getDocs();
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Delete Successfully</span>
							</span>,
							dataRes.data.message,
						);
						swal("Contact has been deleted successfully!", {
							icon: "success",
							buttons:false
						});
						setTimeout(() => {
							swal.close();
						}, 1500);
					} else {
						
					}
				}).catch(function (error) {
					if (error.response) {
						console.log(serverError)
						setServerError(`Server status:${error.response.status} status`);
					}
				});
			}
		});

	}

	const timeChange =(e)=> {
		// console.log(e.target.value)
		setUser({...user, time_zone: e.target.value});
		setUserData({...user, time_zone: e.target.value});
		setTime(e.target.value);
	}

	return (
		<PageWrapper title={extraMenu.editProfile.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ title: 'Edit Profile', to: '/' },
						]}
					/>
					<SubheaderSeparator />
					<span className='text-muted'>{user?.first_name} {user?.last_name}</span>
				</SubHeaderLeft>
				<SubHeaderRight>
					{TABS.EDIT_PROFILE === activeTab && (
						<Button color='info' isOutline icon='Save' onClick={formik.handleSubmit}>
							Update Profile
						</Button>
					)}
					{TABS.SETTING === activeTab && (
						<div>
						{
							sendCode === false ?
							<Button
								color='info'
								isOutline
								icon='Save'
								onClick={formikMobile.handleSubmit}>
								Update
							</Button>
							: <Button
								color='info'
								isOutline
								icon='Save'
								onClick={formikMobileVerify.handleSubmit}>
								Verify Code
							</Button>
						}
						</div>
							
					)}
                    {TABS.PASSWORD === activeTab && (
						<Button
							color='info'
							isOutline
							icon='Save'
							onClick={passwordReset}
                        >
							Change Password
						</Button>
					)}
				</SubHeaderRight>
			</SubHeader>
			<Page>
                {loading &&
					<Loader />
				}
				<div className='row h-100'>

                    {serverError !== "" ? <div className="alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center" >
                        <span className="text-white">{serverError}</span>
                    </div> : ""}

					<div className='col-xl-3 col-lg-4 col-md-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='Person' iconColor='success'>
									<CardTitle>Edit Profile</CardTitle>
									<CardSubTitle>Personal Information</CardSubTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-3'>
									<div className='col-12'>
										<Button
											icon='AccountBox'
											color='success'
											className='w-100 p-3'
											isLight={TABS.EDIT_PROFILE !== activeTab}
											onClick={() => setActiveTab(TABS.EDIT_PROFILE)}>
											{TABS.EDIT_PROFILE}
										</Button>
									</div>
									<div className='col-12 border-bottom' />
									<div className='col-12'>
										<Button
											icon='MobileFriendly'
											color='info'
											className='w-100 p-3'
											isLight={TABS.SETTING !== activeTab}
											onClick={() => setActiveTab(TABS.SETTING)}>
											{TABS.SETTING}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='PhotoCamera'
											color='info'
											className='w-100 p-3'
											isLight={TABS.AVATAR !== activeTab}
											onClick={() => setActiveTab(TABS.AVATAR)}>
											{TABS.AVATAR}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Lock'
											color='info'
											className='w-100 p-3'
											isLight={TABS.PASSWORD !== activeTab}
											onClick={() => setActiveTab(TABS.PASSWORD)}>
											{TABS.PASSWORD}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='LibraryBooks'
											color='info'
											className='w-100 p-3'
											isLight={TABS.DOCUMENT !== activeTab}
											onClick={() => setActiveTab(TABS.DOCUMENT)}>
											{TABS.DOCUMENT}
										</Button>
									</div>
								</div>
							</CardBody>
							<CardFooter>
								<CardFooterLeft className='w-100'>
									<div className='col-12 shadow-3d-container'>
										<Card className="card shadow-sm transition-base rounded-2 mb-0 text-dark bg-l25-secondary bg-l10-secondary-hover">
											<CardHeader className='bg-transparent'>
												<CardLabel>
													<CardTitle>Balance</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody className="pt-0">
												<div className='d-flex align-items-center pb-3'>
													<div className='flex-shrink-0'>
														<Icon
															icon='LocalOffer'
															size='4x'
															color='secondary'
														/>
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-3 mb-0'>
															${calculate2(userData?.credit)}
														</div>
														<div className='text-muted mb-0'>
															Package: {userData?.package_detail?.pkg_name}
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								</CardFooterLeft>
							</CardFooter>
						</Card>
					</div>
					<div className='col-xl-9 col-lg-8 col-md-6'>
						{TABS.EDIT_PROFILE === activeTab && (
                            <Card stretch tag='form' noValidate onSubmit={formik.handleSubmit}> 
                                <CardHeader>
									<CardLabel icon='AccountBox' iconColor='success'>
										<CardTitle>{TABS.EDIT_PROFILE}</CardTitle>
									</CardLabel>
								</CardHeader>
                                <CardBody> 
									<div className='row g-4'>
										<div className='col-md-6'>
											<FormGroup id='firstName' label='First Name' isFloating>
												<Input
													placeholder='First Name'
													autoComplete='additional-name'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.firstName}
													isValid={formik.isValid}
													isTouched={formik.touched.firstName}
													invalidFeedback={formik.errors.firstName}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup id='lastName' label='Last Name' isFloating>
												<Input
													placeholder='Last Name'
													autoComplete='family-name'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.lastName}
													isValid={formik.isValid}
													isTouched={formik.touched.lastName}
													invalidFeedback={formik.errors.lastName}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
                                        <div className='col-md-6'>
											<FormGroup
												id='email'
												label='Email address'
												isFloating
                                            >
												<Input
                                                    readOnly
													type='email'
													placeholder='Email address'
													autoComplete='email'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.email}
													isValid={formik.isValid}
													isTouched={formik.touched.email}
													invalidFeedback={formik.errors.email}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup
												id='phone'
												label='Phone No'
												isFloating
                                            >
												<Input
													placeholder='Phone No'
													autoComplete='phone'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.phone}
													isValid={formik.isValid}
													isTouched={formik.touched.phone}
													invalidFeedback={formik.errors.phone}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup
												id='timeZone'
												label='Select Time Zone'
												isFloating
                                            >
                                                <Select
													ariaLabel='Select Time Zone'
													placeholder='Select Time Zone'
													list={TimeZone}
													onChange={timeChange}
													onBlur={formik.handleBlur}
													value={time}
													isValid={formik.isValid}
													isTouched={formik.touched.timeZone}
													invalidFeedback={formik.errors.timeZone}
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup
												id='country'
												label='Select Country'
												isFloating
                                            >
                                                <Select
													ariaLabel='Select Contry'
													placeholder='Selct Country'
													list={countriesData !== undefined && countriesData.length > 0 ? countriesData : []}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.country}
													isValid={formik.isValid}
													isTouched={formik.touched.country}
													invalidFeedback={formik.errors.country}
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup
												id='city'
												label='City'
												isFloating
                                            >
												<Input
													placeholder='City'
													autoComplete='city'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.city}
													isValid={formik.isValid}
													isTouched={formik.touched.city}
													invalidFeedback={formik.errors.city}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
                                        <div className='col-md-6'>
											<FormGroup
												id='state'
												label='State'
												isFloating
                                            >
												<Input
													placeholder='State'
													autoComplete='state'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.state}
													isValid={formik.isValid}
													isTouched={formik.touched.state}
													invalidFeedback={formik.errors.state}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
                                        <div className='col-md-6'>
											<FormGroup
												id='zip'
												label='Zip'
												isFloating
                                            >
												<Input
													placeholder='Zip'
													autoComplete='zip'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.zip}
													isValid={formik.isValid}
													isTouched={formik.touched.zip}
													invalidFeedback={formik.errors.zip}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
                                        <div className='col-md-6'>
											<FormGroup
												id='address'
												label='Address'
												isFloating
                                            >
												<Input
													placeholder='Address'
													autoComplete='address'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.address}
													isValid={formik.isValid}
													isTouched={formik.touched.address}
													invalidFeedback={formik.errors.address}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
                                        <div className='col-md-6'>
											<FormGroup
												id='address2'
												label='Address 2'
												isFloating
                                            >
												<Input
													placeholder='Address 2'
													autoComplete='address2'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.address2}
													isValid={formik.isValid}
													isTouched={formik.touched.address2}
													invalidFeedback={formik.errors.address2}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup
												id='companyName'
												label='Company Name'
												isFloating
                                            >
												<Input
													placeholder='Company Name'
													autoComplete='company'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.companyName}
													isValid={formik.isValid}
													isTouched={formik.touched.companyName}
													invalidFeedback={formik.errors.companyName}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup
												id='jobTitle'
												label='Job Title'
												isFloating
                                            >
												<Input
													placeholder='Job Title'
													autoComplete='job'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.jobTitle}
													isValid={formik.isValid}
													isTouched={formik.touched.jobTitle}
													invalidFeedback={formik.errors.jobTitle}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
                                        <div className='col-md-6'>
											<FormGroup
												id='website'
												label='Website'
												isFloating
                                            >
												<Input
													placeholder='Website'
													autoComplete='website'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.website}
													isValid={formik.isValid}
													isTouched={formik.touched.website}
													invalidFeedback={formik.errors.website}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
                                        <div className='col-md-6'>
											<FormGroup
												id='skype'
												label='Skype'
												isFloating
                                            >
												<Input
													placeholder='Skype'
													autoComplete='skype'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.skype}
													isValid={formik.isValid}
													isTouched={formik.touched.skype}
													invalidFeedback={formik.errors.skype}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>   
                                        <div className='col-md-6'>
											<FormGroup
												id='facebook'
												label='Facebook'
												isFloating
                                            >
												<Input
													placeholder='Facebook'
													autoComplete='facebook'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.facebook}
													isValid={formik.isValid}
													isTouched={formik.touched.facebook}
													invalidFeedback={formik.errors.facebook}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
                                        <div className='col-md-6'>
											<FormGroup
												id='linkedin'
												label='Linkedin'
												isFloating
                                            >
												<Input
													placeholder='Linkedin'
													autoComplete='linkedin'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.linkedin}
													isValid={formik.isValid}
													isTouched={formik.touched.linkedin}
													invalidFeedback={formik.errors.linkedin}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
                                        <div className='col-md-6'>
											<FormGroup
												id='twitter'
												label='Twitter'
												isFloating
                                            >
												<Input
													placeholder='Twitter'
													autoComplete='twitter'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.twitter}
													isValid={formik.isValid}
													isTouched={formik.touched.twitter}
													invalidFeedback={formik.errors.twitter}
													// validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
									</div>
								</CardBody>
								<CardFooter>
									<CardFooterLeft>
										<Button
											color='info'
											isLink
											type='reset'
											onClick={formik.resetForm}>
											Reset
										</Button>
									</CardFooterLeft>
									<CardFooterRight>
										<Button
											type='submit'
											icon='Save'
											color='info'
											isOutline
											isDisable={!formik.isValid && !!formik.submitCount}>
											Update Profile
										</Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						)}
						{TABS.SETTING === activeTab && (
							<Card
								stretch
								tag='form'
								onSubmit={sendCode === false ? formikMobile.handleSubmit : formikMobileVerify.handleSubmit}
							>
								<CardHeader>
									<CardLabel icon='MobileFriendly' iconColor='info'>
										{sendCode === false ? <><CardTitle>{TABS.SETTING}</CardTitle><p className="mb-0 text-muted">Please enter to change new mobile number</p></> : <><CardTitle>MOBILE VERIFICATION CODE</CardTitle><p className="mb-0 text-muted">Please enter verification code sent to your new mobile number.</p></> }
									</CardLabel>
								</CardHeader>
								<CardBody className='pb-0' isScrollable>
									<div className='row g-4'>
										{
											sendCode === false ?
											<div className='col-lg-12' key='mn-key-159'>
												<div className='row g-4'>
													<div className='col-lg-12'>
														<FormGroup
															id='mobile'
															label='Current Mobile No'
															isFloating>
															<Input
																readOnly
																name="mob ile"
																value={userData?.mobile_no}
															/>
														</FormGroup>
													</div>
													<div className='col-lg-12'>
														<FormGroup
															id='mobileNo'
															label='New Mobile Number'
															isFloating>
															<PhoneInput
																name="phone_no"
																required="required"
																placeholder="Enter phone number"
																value={value}
																onChange={setValue}
															/>
														</FormGroup>
													</div>
												</div>
											</div>
											:null
										}
										{
											sendCode === true ? 
											<div className='col-lg-12' key='mn-key-158'>
												<div className='form-floating' key='mn-key-157'>
													<input
														id="code"
														name="code"
														type="number"
														required="required"
														placeholder="Enter verification code"
														className="form-control"
														onChange={setVcode}
														// value={vcode}
													/>
													<label htmlFor="code" className="form-label">Verify Code</label>
												</div>
												<span className='text-muted'>Write verification code here.</span>
											</div>
											: null
										}
									</div>
								</CardBody>
								<CardFooter>
									<CardFooterLeft>
										{
											sendCode === false ? 
											<Button
												color='info'
												isLink
												type='reset'
												onClick={formikMobile.resetForm}>
												Reset
											</Button>
											: <Button
												color='info'
												isLink
												type='reset'
												onClick={formikMobileVerify.resetForm}>
												Reset
											</Button>
										}
									</CardFooterLeft>
									<CardFooterRight>
										{
											sendCode === false ? 
											<Button
												type='submit'
												icon='Save'
												color='info'
												isOutline
												>
												Update
											</Button> 
											: <Button
												type='submit'
												icon='Save'
												color='info'
												isOutline
												>
												Verify Code
											</Button>
										}
									</CardFooterRight>
								</CardFooter>
							</Card>
						)}
						{TABS.AVATAR === activeTab && (
                            <Card> 
                                <CardHeader>
                                    <CardLabel icon='AddAPhoto' iconColor='info'>
                                        <CardTitle>Change Profile Photo</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row g-4'>
                                        <div className='col-12 mt-3 mb-4'>
                                            <div className='row g-4 align-items-center'>
                                                <div className='col-lg-auto'>
                                                    {
                                                        user?.photo !== undefined && user?.photo !== null ?
                                                        <div className='user-img'>
                                                            <img
                                                                srcSet={avatar}
                                                                src={avatar}
                                                                alt='Avatar'
                                                                width={128}
                                                                height={128}
                                                                className="avatar rounded-circle bg-l25-primary"
                                                            />
                                                        </div>
                                                        : <div className='user-img'>
                                                            <img
                                                                srcSet={USERS.JOHN.srcSet}
                                                                src={USERS.JOHN.srcSet}
                                                                alt='Avatar'
                                                                width={128}
                                                                height={128}
                                                                className="avatar rounded-circle bg-l25-primary"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <div className='col-lg'>
                                                    <div className='row g-4'>
                                                        <div className='col-auto mt-4'>
                                                            <Input
                                                                type='file'
                                                                autoComplete='photo'
																onChange={onFileChange}
                                                            />
                                                        </div>
                                                        <div className='col-auto mt-4'>
                                                            <Button
                                                                color='dark'
                                                                isLight
                                                                icon='Upload'
																onClick={uploadPhoto}
																>
                                                                Upload Avatar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody> 
                            </Card>
                        )}
						{TABS.PASSWORD === activeTab && (
							<Card stretch tag='form' noValidate>
								<CardHeader>
                                    <CardLabel icon='Lock' iconColor='info'>
                                        <CardTitle>{TABS.PASSWORD}</CardTitle>
                                    </CardLabel>
                                </CardHeader>
								<CardBody isScrollable>
                                    <div className='col-12'>
                                        <div className='row g-4'>
                                            <div className='col-12'>
                                                <FormGroup
                                                    id='currentPassword'
                                                    label='Current password'
                                                    isFloating>
                                                    <Input
                                                        type='password'
                                                        placeholder='Current password'
														className={opError}
                                                        onChange={handleOpassswordChange}
                                                        value={opassword}
                                                    />
                                                </FormGroup>
												<div className="invalid-feedback" style={{display:`block`}}>{opMsg}</div>
                                            </div>
                                            <div className='col-12'>
                                                <FormGroup
                                                    id='newPassword'
                                                    label='New password'
                                                    isFloating>
                                                    <Input
                                                        type='password'
                                                        placeholder='New password'
														className={npError}
                                                        onChange={handlePassswordChange}
                                                        value={npassword}
                                                    />
                                                </FormGroup>
												<div className="invalid-feedback" style={{display:`block`}}>{npMsg}</div>
                                            </div>
                                            <div className='col-12'>
                                                <FormGroup
                                                    id='confirmPassword'
                                                    label='Confirm new password'
                                                    isFloating>
                                                    <Input
                                                        type='password'
                                                        placeholder='Confirm new password'
														className={rpError}
                                                        onChange={handleConfirmPassswordChange}
                                                        value={confirmPassword}
                                                    />
                                                </FormGroup>
												<div className="invalid-feedback" style={{display:`block`}}>{rpMsg}</div>
                                            </div>
                                        </div>
                                    </div>
								</CardBody>
                                <CardFooter>
									<CardFooterLeft>
										<Button
											color='info'
											isLink
											type='reset'
											onClick={resetPasswordFrom}
										>
											Reset
										</Button>
									</CardFooterLeft>
									<CardFooterRight>
										<Button
											type='button'
											icon='Save'
											color='info'
											isOutline
											onClick={passwordReset}
										>
											Change Password
										</Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						)}
						{TABS.DOCUMENT === activeTab && (
							<Card stretch style={{ minHeight: 600 }}>
								<CardHeader>
                                    <CardLabel icon='LibraryBooks' iconColor='info'>
                                        <CardTitle>{TABS.DOCUMENT}</CardTitle>
                                    </CardLabel>
                                </CardHeader>
								<CardBody isScrollable>
                                	<div className='row g-4'>
                                        <div className='col-12 mt-3 mb-4'>
                                            <div className='row g-4 align-items-center'>
                                                <div className='col-lg'>
                                                    <div className='row g-4'>
														<div className='col-auto mt-4'>
                                                            <Input
                                                                type='file'
                                                                autoComplete='file'
																onChange={onDocChange}
                                                            />
                                                        </div>
                                                        <div className='col-auto mt-4'>
                                                            <Button
                                                                color='dark'
                                                                isLight
                                                                icon='Upload'
																onClick={uploadDocument}
																>
                                                                Upload Document
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
											<Card stretch className="mt-4"  style={{ minHeight: 400 }}>
												<CardBody isScrollable className='table-responsive'>
													<table className='table table-modern table-hover'>
														<thead>
															<tr>
																<th>ID</th>
																<th>Document</th>
																<th>Added Date</th>
																<th>Created on</th>
																<td />
															</tr>
														</thead>
														<tbody>
															{documents?.map((i) => (
															<tr key={i.id} id={i.id}>
																<td>{i.id}</td>
																<td>
																	<div className='d-flex align-items-center'>
																		<div className='flex-grow-1'>
																			<div className='fs-6 fw-bold'>
																				{i.document}
																			</div>
																		</div>
																	</div>
																</td>
																<td>
																	{moment(i.added_date).format('MMM DD,YYYY, hh:mm a')}
																</td>
																<td>
																	<div>{moment(i.created_at).format('MMM DD,YYYY, hh:mm a')}</div>
																	<div>
																		<small className='text-muted'>
																		<Icon icon='Label' />{' '}	{moment(i.updated_at).format('MMM DD,YYYY, hh:mm a')}
																		</small>
																	</div>
																</td>
																<td>
																	<Dropdown>
																		<DropdownToggle hasIcon={false}>
																			<Button
																				icon='MoreHoriz'
																				color='dark'
																				isLight
																				shadow='sm'
																			/>
																		</DropdownToggle>
																		<DropdownMenu isAlignmentEnd>
																			<DropdownItem>
																				<Button
																					icon='Download'
																					tag='a'
																					href={`${path}/${i.document}`}
																					target="_blank"
																					download
																				>
																					Download
																				</Button>
																			</DropdownItem>
																			<DropdownItem>
																				<Button
																					icon='delete'
																					tag='a'
																					onClick={() => deleteDocument(i.id)}
																				>
																					Delete
																				</Button>
																			</DropdownItem>
																		</DropdownMenu>
																	</Dropdown>
																</td>
															</tr>
															))}
														</tbody>
													</table>
												</CardBody>
												<PaginationButtons
													data={documents}
													totalrows={pagination?.total}
													label='documents'
													setCurrentPage={setCurrentPage}
													currentPage={currentPage}
													perPage={perPage}
													setPerPage={setPerPage}
												/>
											</Card>
                                        </div>
                                    </div>
								</CardBody>
							</Card>
						)}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default EditProfile;
