import React, { lazy } from 'react';
import {dashboardMenu, demoPages, smsMenu, extraMenu } from '../menu';
import Confirmation from '../pages/confirm/Confirmation';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import EditProfile from '../pages/account/EditProfile';
import Settings from '../pages/account/Settings';
import Login from '../pages/presentation/auth/Login';
import LogOut from '../pages/LogOut';

const QUICKSEND = {
	QUICK_SEND: lazy(() => import('../pages/send/QuickSend')),
};
const LISTS = {
	LISTS: lazy(() => import('../pages/lists/ViewList')),
	LIST_ADD: lazy(() => import('../pages/lists/AddList')),
	LIST_EDIT: lazy(() => import('../pages/lists/EditList')),
	EDIT_LIST: lazy(() => import('../pages/lists/Lists')),
	SUPPRESSION_LIST: lazy(() => import('../pages/lists/ViewSepContacts')),
	ADD_SUPPRESSION: lazy(() => import('../pages/lists/AddSepContacts')),
	EXPORT_LIST: lazy(() => import('../pages/lists/ExportContacts')),
	LISTS_DETAILS: lazy(() => import('../pages/contacts/Contacts')),
};
const CUSTOMFIELDS = {
	CUSTOMFIELDS: lazy(() => import('../pages/lists/ViewCustomField')),
};
const CONTACTS = {
	CONTACTS: lazy(() => import('../pages/contacts/ViewContacts')),
	ADD_CONTACT: lazy(() => import('../pages/contacts/AddContact')),
	ADD_CONTACT_LIST: lazy(() => import('../pages/contacts/AddContact')),
	IMPORT_CONTACT: lazy(() => import('../pages/contacts/ImportContacts')),
	IMPORT_CONTACT_LIST: lazy(() => import('../pages/contacts/ImportContacts')),
	EDIT_CONTACTS: lazy(() => import('../pages/contacts/EditContact')),
};
const SENDERIDS = {
	SENDERIDS: lazy(() => import('../pages/senderids/ViewSenderids')),	
	APPLY_SENDERID: lazy(() => import('../pages/senderids/ApplySenderids')),
	ADD_SENDERID: lazy(() => import('../pages/senderids/AddSenderid')),
	EDIT_SENDERID:lazy(() => import('../pages/senderids/EditSenderids')),
	EDIT_USER_SENDERID:lazy(() => import('../pages/senderids/EditUserSenderid')),
};
const BROADCASTS = {
	BROADCASTS: lazy(() => import('../pages/broadcasts/ViewBroadcasts')),		
	ADD_BROADCAST: lazy(() => import('../pages/broadcasts/AddBroadcast')),	
	EDIT_BROADCAST: lazy(() => import('../pages/broadcasts/EditBroadcast')),
	SHEDULED_BROADCASTS_ALL: lazy(() => import('../pages/broadcasts/ViewScheduledBroadcast')),
	SHEDULED_BROADCASTS: lazy(() => import('../pages/broadcasts/ScheduledBroadcast')),
};
const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
const STATS = {
	BROADCAST: lazy(() => import('../pages/statistics/Broadcast')),
	DETAILS: lazy(() => import('../pages/statistics/Details')),
	Transactional: lazy(() => import('../pages/statistics/Transactional')),
	Global: lazy(() => import('../pages/statistics/Global')),
};
const BILLING = {
	CREDIT_CARDS: lazy(() => import('../pages/billing/Cards')),
	ACC_BALANCE: lazy(() => import('../pages/billing/Balance')),
};
const LOGS = {
	LOGS_ACTIVITY: lazy(() => import('../pages/smslogs/ActivityLogs')),
	LOGS_LOGIN: lazy(() => import('../pages/smslogs/LoginLogs')),
	LOGS_NOTIFICATION: lazy(() => import('../pages/smslogs/NotificationLogs')),
};
const PROVIDERS = {
	ALL_PROVIDERS: lazy(() => import('../pages/providers/ViewProviders')),
	ADD_PROVIDERS: lazy(() => import('../pages/providers/AddProvider')),
	EDIT_PROVIDERS: lazy(() => import('../pages/providers/EditProvider')),
};

const apppages = [
	/**
	 * Pages
	 */

	/**
	 * Quick Send Page
	 */
	{
		path: smsMenu.send.path,
		element: <QUICKSEND.QUICK_SEND />,
		exact: true,
	},
	/**
	 * Lists Pages
	 */
	{
		path: smsMenu.lists.subMenu.listAll.path,
		element: <LISTS.LISTS />,
		exact: true,
	},
	{
		path: `${smsMenu.lists.subMenu.listAll.path}/:id`,
		element: <LISTS.LISTS_DETAILS />,
		exact: true,
	},
	{
		path: `${smsMenu.lists.subMenu.listAll.path}/add`,
		element: <LISTS.LIST_ADD />,
		exact: true,
	},
	{
		path: `${smsMenu.lists.subMenu.listAll.path}/:id/edit`,
		element: <LISTS.LIST_EDIT />,
		exact: true,
	},
	{
		path: `${smsMenu.lists.subMenu.listAll.path}/:id/export`,
		element: <LISTS.EXPORT_LIST />,
		exact: true,
	},
	{
		path: `${smsMenu.lists.subMenu.listAll.path}/:id/edit`,
		element: <LISTS.EDIT_LIST />,
		exact: true,
	},
	{
		path: smsMenu.lists.subMenu.SuppressionList.path,
		element: <LISTS.SUPPRESSION_LIST />,
		exact: true,
	},
	{
		path: `${smsMenu.lists.subMenu.SuppressionList.path}/add`,
		element: <LISTS.ADD_SUPPRESSION />,
		exact: true,
	},
	{
		path: smsMenu.lists.subMenu.customFieldsAll.path,
		element: <CUSTOMFIELDS.CUSTOMFIELDS />,
		exact: true,
	},

	/**
	 * Contact Pages
	 */
	{
		path: smsMenu.contacts.subMenu.contactsAll.path,
		element: <CONTACTS.CONTACTS />,
		exact: true,
	},
	{
		path: smsMenu.contacts.subMenu.addContact.path,
		element: <CONTACTS.ADD_CONTACT />,
		exact: true,
	},
	{
		path: `${smsMenu.contacts.subMenu.addContact.path}/:id/`,
		element: <CONTACTS.ADD_CONTACT_LIST />,
		exact: true,
	},
	{
		path: smsMenu.contacts.subMenu.importContact.path,
		element: <CONTACTS.IMPORT_CONTACT />,
		exact: true,
	},
	{
		path: `${smsMenu.contacts.subMenu.importContact.path}/:id/`,
		element: <CONTACTS.IMPORT_CONTACT_LIST />,
		exact: true,
	},
	{
		path: `${smsMenu.contacts.subMenu.contactsAll.path}/:id/edit`,
		element: <CONTACTS.EDIT_CONTACTS />,
		exact: true,
	},
	/**
	 * Broadcast Pages
	 */
	{
		path: smsMenu.broadcasts.subMenu.broadcastsAll.path,
		element: <BROADCASTS.BROADCASTS />,
		exact: true,
	},
	{
		path: smsMenu.broadcasts.subMenu.addbroadcast.path,
		element: <BROADCASTS.ADD_BROADCAST />,
		exact: true,
	},
	{
		path: `${smsMenu.broadcasts.subMenu.broadcastsAll.path}/:id/edit`,
		element: <BROADCASTS.EDIT_BROADCAST />,
		exact: true,
	},
	{
		path: smsMenu.broadcasts.subMenu.sheBroadcastAll.path,
		element: <BROADCASTS.SHEDULED_BROADCASTS_ALL />,
		exact: true,
	},
	{
		path: `${smsMenu.broadcasts.subMenu.sheBroadcastAll.path}/add`,
		element: <BROADCASTS.SHEDULED_BROADCASTS />,
		exact: true,
	},
	/**
	 * SenderID Pages
	 */
	{
		path: smsMenu.senderid.subMenu.senderidsAll.path,
		element: <SENDERIDS.SENDERIDS />,
		exact: true,
	},
	{
		path: smsMenu.senderid.subMenu.applySenderid.path,
		element: <SENDERIDS.APPLY_SENDERID />,
		exact: true,
	},
	{
		path: smsMenu.senderid.subMenu.addSenderid.path,
		element: <SENDERIDS.ADD_SENDERID />,
		exact: true,
	},
	{
		path: `${smsMenu.senderid.subMenu.senderidsAll.path}/:id/edit`,
		element: <SENDERIDS.EDIT_SENDERID />,
		exact: true,
	},
	{
		path: `${smsMenu.senderid.subMenu.senderidsAll.path}/user/:sid/edit`,
		element: <SENDERIDS.EDIT_USER_SENDERID />,
		exact: true,
	},
	/**
	 * Gateway Providers
	 */
	{
		path: smsMenu.providers.subMenu.providersAll.path,
		element: <PROVIDERS.ALL_PROVIDERS />,
		exact: true,
	},{
		path: smsMenu.providers.subMenu.addProviders.path,
		element: <PROVIDERS.ADD_PROVIDERS />,
		exact: true,
	},
	{
		path: `${smsMenu.providers.subMenu.providersAll.path}/:pid/edit`,
		element: <PROVIDERS.EDIT_PROVIDERS />,
		exact: true,
	},
	/**
	 * Stats
	 */
	{
		path: smsMenu.stats.subMenu.broadcast.path,
		element: <STATS.BROADCAST />,
		exact: true,
	},
	{
		path: `${smsMenu.stats.subMenu.broadcast.path}/:id`,
		element: <STATS.DETAILS />,
		exact: true,
	},
	{
		path: smsMenu.stats.subMenu.transactional.path,
		element: <STATS.Transactional />,
		exact: true,
	},
	{
		path: smsMenu.stats.subMenu.global.path,
		element: <STATS.Global />,
		exact: true,
	},
	/**
	 * Billing/Cards
	 */
	{
		path: smsMenu.billing.subMenu.cards.path,
		element: <BILLING.CREDIT_CARDS />,
		exact: true,
	},
	{
		path: smsMenu.billing.subMenu.balance.path,
		element: <BILLING.ACC_BALANCE />,
		exact: true,
	},
	/**
	 * Logs
	 */
	{
		path: smsMenu.logs.subMenu.activityLogs.path,
		element: <LOGS.LOGS_ACTIVITY />,
		exact: true,
	},
	{
		path: smsMenu.logs.subMenu.loginLogs.path,
		element: <LOGS.LOGS_LOGIN />,
		exact: true,
	},
	{
		path: smsMenu.logs.subMenu.notificationLogs.path,
		element: <LOGS.LOGS_NOTIFICATION />,
		exact: true,
	},
];
const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},

	/** ************************************************** */

	/**
	 * Auth Page
	 */
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: 'logout',
		element: <LogOut />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
];

const extra = [
	/**
		 * Email Confirmation
		 */
	{
		path: `${extraMenu.confirmation.path}/:id`,
		element: <Confirmation />,
		exact: true,
	},
	{
		path: `${extraMenu.resetPassword.path}/:token`,
		element: <ResetPassword />,
		exact: true,
	},
	{
		path: extraMenu.editProfile.path,
		element: <EditProfile />,
		exact: true,
	},
	{
		path: extraMenu.accountSettings.path,
		element: <Settings />,
		exact: true,
	},
]

const contents = [...presentation, ...apppages, ...extra];
export default contents;
