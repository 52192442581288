import React from 'react';
import { demoPages, extraMenu } from '../menu';
import Footer from '../layout/Footer/Footer';

const footers = [
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: `${extraMenu.confirmation.path}/:id`, element: null, exact: true },
	{ path: `${extraMenu.resetPassword.path}/:token`, element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ path: '*', element: <Footer /> },
];

export default footers;
