import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import axios from 'axios';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import LogoLogin from '../../../components/LogoLogin';
import useDarkMode from '../../../hooks/useDarkMode';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';
import Loader from '../../Loader/Loader';

// eslint-disable-next-line react/prop-types
const LoginHeader = () => {
	return (
		<>
			<div className='text-center h4 fw-bold mt-5'>Welcome to MumaraSMS</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};
const ForgetHeader = () => {
	return (
		<>
			<div className='text-center h4 fw-bold mt-5'>Welcome to MumaraSMS</div>
			<div className='text-center h4 text-muted mb-1'>Forgot Your Password?</div>
			<div className='text-center text-muted mb-5'>Confirm your email and we will send the instruction.</div>
		</>
	);
};

const Login = () => {

	// const [ip, setIP] = useState('');
	// const getData  = useCallback(async () => {
	// 	const res = await axios.get('https://geolocation-db.com/json/')
	// 	// console.log(res.data);
	// 	setIP(res.data.IPv4)
	// }, [setIP]);

	useEffect(() => {
		// getData();
		removeUser();
	}, [removeUser]);
	
	const navigate = useNavigate();
	const { baseUrl } = useDarkMode();
	const [email, setEmail] = useState("");
	const [email2, setEmail2] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState(false);
	const [loginError1, setLoginError1] = useState(false);
	const [loginError2, setLoginError2] = useState(false);
	const [loginError3, setLoginError3] = useState(false);
	const [user, setUser, removeUser] = useLocalStorage('user', null);
    const [forget, setForget] = useState(false);
    const [emailerror, setEmailerror] = useState(false);
    const [forgotError, setForgotError] = useState(false);
    const [fErrorMsg, setfErrorMsg] = useState(false);
	const [loading, setLoading] = useState(false);

	
	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	}
	const handlePassswordChange = (e) => {
		setPassword(e.target.value);
	}
	const handleOnClick = async () => {
		setLoading(true)
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ 'email': email,'password':password })
		};
		const res = await fetch(`${baseUrl}login`, requestOptions);
		const data = await res.json();
		
		if(data.status=== "active"){
			setUser(data)
			setLoginError(false);				
			setLoginError1(false);
			setLoginError2(false);	
			setLoginError3(false);	
			setForget(false);	
			navigate('/');
		} else if(data?.status === "unverified"){	
			setLoginError(false);	
			setLoginError2(false);	
			setLoginError3(false);				
			setLoginError1(true);
			setForget(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Account Unverified!</span>
				</span>,
				"Your account is unverified. Check your email.",
			);
			setLoading(false);		
			removeUser();		
		} else if(data?.status === "inactive"){	
			setLoginError(false);					
			setLoginError1(false);
			setLoginError3(false);
			setLoginError2(true);	
			setForget(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Account Deactivated!</span>
				</span>,
				"Your account has been deactivated. Contact to administrator.",
			);
			setLoading(false);		
			removeUser();		
		} else if(data?.status === "closed"){	
			setLoginError(false);					
			setLoginError1(false);
			setLoginError2(false);	
			setLoginError3(true);	
			setForget(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Account Suspended!</span>
				</span>,
				"Your account has been Suspended. Contact to administrator.",
			);
			setLoading(false);		
			removeUser();		
		} else {						
			setLoginError1(false);
			setLoginError2(false);
			setLoginError3(false);
			setLoginError(true);
			setForget(false);
			console.log(user)
			setLoading(false);		
			removeUser();		
		}
		setTimeout(() => {
			setLoading(false);
		}, 5000);
	}

	const handleEmailForgot = (e) => {
		// setEmailerror("");
		setEmail2(e.target.value);
	}

	const handleForgotPassword = () => {
		setEmailerror("");
		setForgotError(false)
		if(email2 === "") {
			setEmailerror("Email field is required")
		} else if (email2 !== "" && !email2.match(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)) {
			setEmailerror("Email address invalid!")
		} else {
			setLoading(true)
			setEmailerror("");
			const emailData = {email: email2}
			axios.post(`${baseUrl}sendEmailResetPasswordLink`, emailData )
			.then(res => {
				if(res?.data?.status === 'success') {
					setLoading(false)
					setEmailerror("");
					setEmail2("");
					setForget(false);
					setForgotError(false);
					setEmail(res?.data?.email);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Forgot Password</span>
						</span>,
						res?.data?.message,
					);
				} else {
					// console.log(res.status);
					setLoading(false)
					setForget(true);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Forgot Password</span>
						</span>,
						res?.data?.message,
					);
				}
			}).catch(function (error) {
				setForgotError(true)
				setLoading(false)
				if (error.response) {
					console.log(error.response.status)
					setfErrorMsg(`Server status:${error.response.status} status`);
				}
			});
		}
	}

	const forgetFalse =()=> {
		setForget(false);
	}
	const forgetTrue =()=> {
		setForget(true);
	}

	const handleKeyDown =(e)=> {
		if (e.keyCode === 13) {
			forgetFalse();
		}
	}
	const handleKeyDown2 =(e)=> {
		if (e.keyCode === 13) {
			forgetTrue();
		}
	}
	
	return (

		<PageWrapper
			title="Login"
			className="bg-success">
			<Page className='p-0'>
				{loading &&
					<Loader />
				}
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						{
							forget === false ?
							<Card className='shadow-3d-dark' data-tour='login-page'>
								<CardBody>
									<div className='text-center my-2'>
										<Link to='/' >
											<LogoLogin width={200} />
										</Link>
									</div>

									<LoginHeader />
									
									{/* <input type="hidden" name="ip" id="ip" value={ip} /> */}


									{loginError ? 
										<div className='alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center'><span className="text-white">incorrect Username/Password</span></div>
										: null
									}
									{loginError1 ? 
										<div className='alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center'><span className="text-white">Your account is unverified. Check your email.</span></div>
										: null
									}	
									{loginError2 ? 
										<div className='alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center'><span className="text-white">Your account has been deactivated. Contact to administrator.</span></div>
										: null
									}	
									{loginError3 ? 
										<div className='alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center'><span className="text-white">Your account has been Suspended. Contact to administrator.</span></div>
										: null
									}		
									<form className='row g-4'>
										<span style={{float: `right`, display:`flex`, cursor:`pointer`, justifyContent:`flex-end`}} className="text-info" onClick={forgetTrue} onKeyDown={handleKeyDown2} role="button" tabIndex="0">Forgot Password?</span>
										<div className='col-12 mt-0'>
											<FormGroup
												id='userEmail'
												isFloating
												onChange={handleEmailChange}
												label='Email Address'>
												<Input autoComplete='email' name="email" />
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup
												id='login-password'
												isFloating
												onChange={handlePassswordChange}
												label='Password'>
												<Input
													type='password'
													autoComplete='password'
													name="password"

												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												color='success'
												className='w-100 py-3'
												onClick={handleOnClick}>
												Login
											</Button>
										</div>
									</form>
								</CardBody>
							</Card>
							: <Card className='shadow-3d-dark' data-tour='forgot-page'>
								<CardBody>
									<div className='text-center my-2'>
										<Link to='/' >
											<LogoLogin width={200} />
										</Link>
									</div>

									<ForgetHeader />

									{forgotError ? 
										<div className='alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center'><span className="text-white">{fErrorMsg}</span></div>
										: null
									}
									
									<form className='row g-4'>
										<span style={{float: `right`, display:`flex`, cursor:`pointer`, justifyContent:`flex-end`}} className="text-info" onClick={forgetFalse} onKeyDown={handleKeyDown} role="button" tabIndex="0">Remember Password?</span>
										<div className='col-12 mt-0'>
											<FormGroup
												id='forgotEmail'
												isFloating
												required="required"
												onChange={handleEmailForgot}
												label='Email Address'>
												<Input autoComplete='email' name="email" />
											</FormGroup>
											<span className='text-danger'>{emailerror}</span>
										</div>
										<div className='col-12'>
											<Button
												color='success'
												className='w-100 py-3'
												onClick={handleForgotPassword}>
												Reset Password
											</Button>
										</div>
									</form>
								</CardBody>
							</Card>
						}
							
						<div className='text-center'>
							<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Privacy policy</Link>
							<Link to='//www.mumara.com' target='_blank' className='link-dark text-decoration-none me-3'>Terms of use</Link>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
