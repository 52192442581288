import React, { useContext, useLayoutEffect, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import axios from 'axios';
import classNames from 'classnames';
import Button from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import LANG, { getLangWithKey } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import Notifications from '../../dashboard/Notifications';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus, baseUrl } = useDarkMode();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { i18n } = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
			</span>,
			'You updated the language of the site. (Only "Aside" was prepared as an example.)',
		);
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	const navigate = useNavigate();
	const [user, setUser, removeUser] = useLocalStorage('user', null);
	const [credits] = useLocalStorage('credits', null);

	const logout = () => {
		const paramsLogout = { user_id: user?.user_id, api_token: user?.api_token };
        axios.post(`${baseUrl}clientLogoutActivity`, paramsLogout, { 
        })
        .then(dataRes => { // then print response status
            if (dataRes.data.status === 'success') {
				setUser();
                showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>User Logout: </span>
					</span>,
					dataRes.data.message,
				);
            } else {
                showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>User Logout: </span>
					</span>,
					dataRes.data.message,
				);
            }
        })
		removeUser();
        navigate('/auth-pages/login');
    };

	const [crdBtn, setCrdBtn] = useState(false);

	useEffect(() => {
		if(credits !== null  && credits < 10) {
			setCrdBtn(true)
		}
	}, [credits]);


	const addBalance=()=> {
		navigate('/billing/balance');
	}

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				{
					crdBtn === true ? 
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Add Balance'>
							<Button
								className='btn btn-danger btn-sm mt-2'
								data-tour='dark-mode'
								onClick={addBalance}
							>
								<Icon
									icon="AttachMoney"
									color="white"
									className='btn-icon'
								/> Low Balance
							</Button>
						</Popovers>
					</div>
					: null
				}

				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>

				{/* Lang Selector */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							{typeof getLangWithKey(i18n.language)?.icon === 'undefined' ? (
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									className='btn-only-icon'
									aria-label='Change language'
									data-tour='lang-selector'>
									<Spinner isSmall inButton='onlyIcon' isGrow />
								</Button>
							) : (
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon={getLangWithKey(i18n.language)?.icon}
									aria-label='Change language'
									data-tour='lang-selector'
								/>
							)}
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button
										icon={LANG[i].icon}
										onClick={() => changeLanguage(LANG[i].lng)}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{/* Quick Panel */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							{/* eslint-disable-next-line react/jsx-props-no-spreading */}
							<Button {...styledBtn} icon='Tune' aria-label='Quick menu' />
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
							<div className='row g-0'>
								<div
									className={classNames(
										'col-12',
										'p-4',
										'd-flex justify-content-center',
										'fw-bold fs-5',
										'text-info',
										'border-bottom border-info',
										{
											'bg-l25-info': !darkModeStatus,
											'bg-lo25-info': darkModeStatus,
										},
									)}>
									Useful Links
								</div>
								<a href="https://help.mumara.com/hc/en-us"
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-end border-bottom',
										{ 'border-dark': darkModeStatus },
									)} style={{textDecoration:`none`, color:`#323232`}}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='MenuBook' size='3x' color='info' />
										<span>Knowledge</span>
										<small className='text-muted'>base</small>
									</div>
								</a>
								<a href="https://community.mumara.com/"
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-bottom',
										{ 'border-dark': darkModeStatus },
									)} style={{textDecoration:`none`, color:`#323232`}}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='MarkChatUnread' size='3x' color='success' />
										<span>Community</span>
										<small className='text-muted'>Support</small>
									</div>
								</a>
								<a href="https://community.mumara.com/forums/feature-request.14/"
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-end',
										{ 'border-dark': darkModeStatus },
									)} style={{textDecoration:`none`, color:`#323232`}}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='EditNote' size='3x' color='warning' />
										<span>Feature</span>
										<small className='text-muted'>Request</small>
									</div>
								</a>
								<a href="https://community.mumara.com/forums/bug-reporting.15/" className='col-6 p-4 transition-base cursor-pointer bg-light-hover' style={{textDecoration:`none`, color:`#323232`}}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='ModeEdit' size='3x' color='danger' />
										<span>Report</span>
										<small className='text-muted'>a Bug</small>
									</div>
								</a>
							</div>
						</DropdownMenu>
					</Dropdown>
				</div>

				{/*	Notifications */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Notifications'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon='Notifications'
							onClick={() => setOffcanvasStatus(true)}
							aria-label='Notifications'
						/>
					</Popovers>
				</div>
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Logout'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon='Logout'
							onClick={logout}
							aria-label='Logout'
						/>
					</Popovers>						
				</div>
				{afterChildren}
			</div>

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>
						Notifications  
						<span style={{display:`inline-block`, fontSize:0, width:`10px`}}>x</span>
						<Button
							icon='RemoveRedEye'
							size='sm'
							color='success'
							tag='a'
							shadow='sm'
							className="ml-2"
							to='../logs/notifications'
						>
							View All
						</Button>
					</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<Notifications />
					{/* <Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
						4 new components added.
					</Alert>
					<Alert icon='ThumbUp' isLight color='warning' className='flex-nowrap'>
						New products added to stock.
					</Alert>
					<Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
						There are products that need to be packaged.
					</Alert>
					<Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
						Your food order is waiting for you at the consultation.
					</Alert>
					<Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
						Escalator will turn off at 6:00 pm.
					</Alert> */}
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
