import React, { useState, useEffect, useCallback, useRef } from 'react';
import { MultiSelect } from "react-multi-select-component";
import { useFormik } from 'formik';
import { useLocalStorage } from 'react-use';
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import SubHeader, {
	SubHeaderLeft,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Button from '../../components/bootstrap/Button';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Select from '../../components/bootstrap/forms/Select';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import validate from './helper/editPagesValidate';
import Icon from '../../components/icon/Icon';
import Modal, {
	ModalBody,
	ModalHeader,
	ModalTitle,
} from '../../components/bootstrap/Modal';
import Label from '../../components/bootstrap/forms/Label';
import { extraMenu } from '../../menu';
import showNotification from '../../components/extras/showNotification';
import useDarkMode from '../../hooks/useDarkMode';
import Loader from '../Loader/Loader';
import '../contacts/addcontacts.css';

const styles = {
    label: {
        width: `auto%`,
        display: `inline-block`,
        marginLeft: 5,
        verticalAlign: `middle`,
        marginBottom: 0,
        cursor: `pointer`,
        position: `relative`
    },
    select: {
        lineHeight: `30px`
    },
    prl0: {
        paddingLeft: 0,
		paddingRight:0
    }
}

const TimeZone = [
	{ value: '-12:00', text: '(GMT -12:00) Eniwetok, Kwajalein' },
	{ value: '-11:00', text: '(GMT -11:00) Midway Island, Samoa' },
	{ value: '-10:00', text: '(GMT -10:00) Hawaii' },
	{ value: '-09:00', text: '(GMT -9:00) Alaska' },
	{ value: '-08:00', text: '(GMT -8:00) Pacific Time (US &amp; Canada)' },
	{ value: '-07:00', text: '(GMT -7:00) Mountain Time (US &amp; Canada)' },
	{ value: '-06:00', text: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City' },
	{ value: '-05:00', text: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima' },
	{ value: '-04:00', text: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz' },
	{ value: '-03:30', text: '(GMT -3:30) Newfoundland' },
	{ value: '-03:00', text: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
	{ value: '-02:00', text: '(GMT -2:00) Mid-Atlantic' },
	{ value: '-01:00', text: '(GMT -1:00 hour) Azores, Cape Verde Islands' },
	{ value: '+00:00', text: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
	{ value: '+01:00', text: '(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris' },
	{ value: '+02:00', text: '(GMT +2:00) Kaliningrad, South Africa' },
	{ value: '+03:00', text: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
	{ value: '+03:30', text: '(GMT +3:30) Tehran' },
	{ value: '+04:00', text: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
	{ value: '+04:30', text: '(GMT +4:30) Kabul' },
	{ value: '+05:00', text: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
	{ value: '+05:30', text: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
	{ value: '+06:00', text: '(GMT +6:00) Almaty, Dhaka, Colombo' },
	{ value: '+07:00', text: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
	{ value: '+08:00', text: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
	{ value: '+09:00', text: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
	{ value: '+09:30', text: '(GMT +9:30) Adelaide, (GMT +9:30) Adelaide, Darwin' },
	{ value: '+10:00', text: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
	{ value: '+11:00', text: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
	{ value: '+12:00', text: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
];

// const options = [
//     { label: "Pakistan", value: "1" },
//     { label: "China", value: "2" },
//     { label: "India", value: "3" },
// ];

const Settings = () => {
    
	const navigate = useNavigate();
	const [user, setUser, removeUser] = useLocalStorage('user', null);
	const {baseUrl} = useDarkMode();
	const [userData, setUserData] = useState([]);
	const [serverError, setServerError] = useState("");
	const [loading, setLoading] = useState(true);
	const [countriesData, setCountriesData] = useState(true);
	const [countriesData2, setCountriesData2] = useState(true);
	const [timeZ, setTimeZ] = useState(true);
	const [showTimeZ, setShowTimeZ] = useState(true);
	const [allowContries, setAllowContries] = useState([]);
    const [multiple, setMultiple] = useState([]);
	const [showCntr, setShowCntr] = useState(true);
	const [showCntr2, setShowCntr2] = useState(false);
	const [allowedCountries, setAllowedCountries] = useState([]);
	const [acData, setAcData] = useState(true);
	const [pkgData, setPkgData] = useState([]);
	const [modalStatus, setModalStatus] = useState(false);
	const [modalStatus2, setModalStatus2] = useState(false);
	const [pid, setPid] = useState("");
	const [networks, setNetworks] = useState([]);
	const [showNetworks, setShowNetworks] = useState(false);
	
	
    const TABS = {
		ACCOUNT_SETINGS: 'Account Settings',
		PACKAGES: 'View Packages',
	};
	const [activeTab, setActiveTab] = useState(TABS.ACCOUNT_SETINGS);

	useEffect(() => {
		fetchUser();
		getCountries();
		getCountries2();
		getAllowedCountries();
		getPackages();
		loadCardsData();
		// return () => {};
	}, [fetchUser, getCountries, getCountries2, getAllowedCountries, getPackages, loadCardsData]);

    const fetchUser = useCallback(async () => {
		setServerError("")
		const param = { user_id: user?.id, api_token: user?.token};
		axios.post(`${baseUrl}getClientDetail`, param, { // receive two parameter endpoint url ,form data 
		}).then(dataRes => { // then print response status
			if (dataRes?.data?.status === "success") {
				setUserData(dataRes?.data?.data);
                setTimeZ(dataRes?.data?.data?.time_zone)
				setLoading(false);
			} else {
				setUserData([]);
				setLoading(false);
			}
		}).catch(function (error) {
			setLoading(false);
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
		})
	}, [baseUrl, user]);

    const getCountries = useCallback(async () => {
		setServerError("")
		const param = { user_id: user?.id, api_token: user?.token};
		axios.post(`${baseUrl}getClientDestinationCountries`, param, { // receive two parameter endpoint url ,form data 
		}).then(dataRes => { // then print response status
			// console.log(dataRes?.data)
			if (dataRes?.data?.status === "success") {
				setCountriesData(dataRes?.data?.data);
				setLoading(false);
			} else {
				setCountriesData([]);
				setLoading(false);
			}
		}).catch(function (error) {
			setLoading(false);
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
		})
	}, [baseUrl, user]);

    const getCountries2 = useCallback(async () => {
		setServerError("")
		const param = { user_id: user?.id, api_token: user?.token};
		axios.post(`${baseUrl}getCountries`, param, { // receive two parameter endpoint url ,form data 
		}).then(dataRes => { // then print response status
			// console.log(dataRes?.data)
			if (dataRes?.data?.status === "success") {
				setCountriesData2(dataRes?.data?.data);
				setLoading(false);
			} else {
				setCountriesData2([]);
				setLoading(false);
			}
		}).catch(function (error) {
			setLoading(false);
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
		})
	}, [baseUrl, user]);

    const getAllowedCountries = useCallback(async () => {
		setServerError("")
		const param = { user_id: user?.id, api_token: user?.token};
		axios.post(`${baseUrl}getRequestCountries`, param, { // receive two parameter endpoint url ,form data 
		}).then(dataRes => { // then print response status
			// console.log(dataRes?.data)
			if (dataRes?.data?.status === "success") {
				setAllowedCountries(dataRes?.data?.data);
				if(dataRes?.data?.data.length > 0) {
					setAcData(true);
				} else {
					setAcData(false);
				}
				setLoading(false);
			} else {
				setAllowedCountries([]);
				setLoading(false);
			}
		}).catch(function (error) {
			setLoading(false);
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
		})
	}, [baseUrl, user]);

	const getPackages = useCallback(async () => {
		setServerError("")
		const param = { user_id: user?.id, api_token: user?.token};
		axios.post(`${baseUrl}getPackages`, param, { // receive two parameter endpoint url ,form data 
		}).then(dataRes => { // then print response status
			// console.log(dataRes?.data)
			if (dataRes?.data?.status === "success") {
				setPkgData(dataRes?.data?.data);
				// setPkgData([]);
				setLoading(false);
			} else {
				setPkgData([]);
				setLoading(false);
			}
		}).catch(function (error) {
			setLoading(false);
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
		})
	}, [baseUrl, user]);

	const formik = useFormik({
		initialValues: {
			country: [],
		},
		validate,
		onSubmit: () => {
			console.log(formik.values)
		},
	});

	function calculate2(val) {
		const num = parseFloat(val); // Read Value from DOM
		const rounded = num.toFixed(2); // Round Number
		return Number(rounded);
	}

    const changeTimeZone =(e)=> {
        setTimeZ(e.target.value)
        // console.log(e.target.value)
    }

    const updateTimeZone =()=> {
        // console.log(timeZ)
        setLoading(true);
        const passParams = { 
            user_id: user?.user_id, 
            api_token: user?.api_token, 
            time_zone:timeZ,
        };
        axios.post(`${baseUrl}changeTimeZone`, passParams, { 
        })
        .then(dataRes => { 
            if(dataRes?.data?.status==="success"){
                showNotification(
                    <span className='d-flex align-items-center'>
                        <Icon icon='Info' size='lg' className='me-1' />
                        <span>Change Time Zone</span>
                    </span>,
                    dataRes?.data?.message,
                );
                user.time_zone = timeZ;
                setTimeout(() => {
                    setShowTimeZ(true);
                    setLoading(false);
                }, 1000);
            } else {
                setLoading(false);
                showNotification(
                    <span className='d-flex align-items-center'>
                        <Icon icon='Info' size='lg' className='me-1' />
                        <span>Change Time Zone error!</span>
                    </span>,
                    dataRes?.data?.message,
                );
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            if (error.response) {
                setServerError(`Server status:${error.response.status} status`);
            }
        });
    }

    const onOffTZEdit =()=> {
        setShowTimeZ(false)
    }

    const handleChange =(e)=> {
        const selected = e.target.options;
        const value = [];
        for (let i = 0, l = selected.length; i < l; i += 1) {
          if (selected[i].selected) {
            value.push(selected[i].value);
          }
        }
        setAllowContries(value);
        console.log(value)
    }

	const hideAC =()=> {
		setShowCntr(false)
		setShowCntr2(true)
	}

	const hideAC2 =()=> {
		if(acData === true) {
			setLoading(true)
			const mpc = [...multiple];
			const mpcVal = mpc.map(item => item.value)
			// console.log(mpcVal)
			if(multiple !== undefined && multiple.length > 0) {
				const passParams = { 
					user_id: user?.user_id, 
					api_token: user?.api_token, 
					countries:JSON.stringify(mpcVal),
				};
				axios.post(`${baseUrl}applyForDestinationCountries`, passParams, { 
				})
				.then(dataRes => { 
					if(dataRes?.data?.status==="success"){
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Request Sent for Allow Countries</span>
							</span>,
							dataRes?.data?.message,
						);
						getCountries();
						setTimeout(() => {
							setShowCntr(true)
							setShowCntr2(false)
							setLoading(false)
						}, 1000);
					} else if(dataRes?.data?.status==="already_appied") {
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Countries Already Approved</span>
							</span>,
							dataRes?.data?.message,
						);
						setLoading(false)
					}
					else {
						setLoading(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Allow Countries error!</span>
							</span>,
							dataRes?.data?.message,
						);
					}
					setLoading(false);
				}).catch(function (error) {
					setLoading(false);
					if (error.response) {
						setServerError(`Server status:${error.response.status} status`);
					}
				});
			} else {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>No Country Selected</span>
					</span>,
					"Please select destination country for allow to admin",
				);
				setLoading(false)
			}
				
		} else {
			setShowCntr(true)
			setShowCntr2(false)
		}
	}

	const hideAC3 =()=> {
		setShowCntr(true)
		setShowCntr2(false)
	}

	const openModal =(id)=> {
		setPid(id);
		setShowNetworks(false)
		// console.log(id)
	}

	const getCountryPrice =(e)=> {
		const cid = e.target.value;
		if(cid === '') {
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Select Country</span>
				</span>,
				"Please Select Country Networks Price",
			);
		} else {
			setLoading(true);
			const networkParams = { 
				user_id: user?.user_id, 
				api_token: user?.api_token, 
				pkg_id:pid,
				country_id:cid,
			};
			axios.post(`${baseUrl}getCountryPrices`, networkParams, { 
			})
			.then(dataRes => { 
				if(dataRes?.data?.status==="Exception"){
					setNetworks(dataRes.data.data)
					setShowNetworks(true)
					setLoading(false);
				} else {
					setLoading(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Networks Data Error!</span>
						</span>,
						"Countries Network Data not available",
					);
					setNetworks(dataRes.data.data)
					setShowNetworks(true)
				}
				setLoading(false);
			}).catch(function (error) {
				setLoading(false);
				if (error.response) {
					setServerError(`Server status:${error.response.status} status`);
				}
			});
		}
		// console.log(e.target.value, pid)
	}

	const [cvc, setCvc] = useState("");
	const [scard, setScard] = useState("");
	const [cards, setCards] = useState([]);
	const [plid, setPlid] = useState([]);
	const [invId, setInvId] = useState("");
	const [orderId, setOrderId] = useState("");
	const [invAmount, setInvAmount] = useState("");
	const [payStep1, setPayStep1] = useState(true);
	const ref = useRef(null);
	const ref2 = useRef(null);

	const loadCardsData = useCallback(async () => {
		const params3 = { user_id: user.user_id, api_token: user.api_token };
		axios.post(`${baseUrl}getPaymentMethod`, params3, { // receive two parameter endpoint url ,form data 
		})
		.then(dataRes => { // then print response status
			if (dataRes?.data?.status === "success") {
				setCards(dataRes?.data?.data?.paymethods);
				setLoading(false);
			} else {
				setCards([]);
				setLoading(false);
			}
		}).catch(function (error) {
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
			if(error?.response?.status === 429 || error?.response?.status === "429") {
				removeUser();
				setUser();
				navigate('/auth-pages/login');
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Session Exired due to multiple request</span>
					</span>,
					"Your Session has been expired, please login again.",
				);
			}
		})
	}, [user, setUser, removeUser, navigate, baseUrl]);

	const changeCVC =(e)=> {
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			if(e.target.value.length < 5 ){
				setCvc(e.target.value);
			}
		}
	}
	const changeCard =(e)=> {
		setScard(e.target.value);
	}

	const changePlan =(id)=> {
		setPlid(id)
		swal({
			title: "Change Plan?",
			text: "Once changed, you will be lost your package and remaining credits.",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  })
		  .then((changed) => {
			if (changed) {
				setPayStep1(false)
				setCvc("");
				setScard("");
				setInvAmount("");
				setOrderId("");
				setInvId("");
				setLoading(false)
				setModalStatus2(true)
			}
		});
	}

	const updatePackage =()=> {
		// console.log(plid, scard, cvc, invId, invAmount, orderId)
		if(scard === "") {
			ref2.current.focus();
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Select Credit Card</span>
				</span>,
				"Select Credit Card to chnage plan.",
			);
		}
		else if(cvc === ""){
			ref.current.focus();
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Select Credit Card</span>
				</span>,
				"Select Credit Card to chnage plan.",
			);
		} else {
			setLoading(true)
			const planParams = {user_id: user?.user_id, api_token: user?.api_token, whmcs_service_id: user?.whmcs_service_id, whmcs_package_id: plid, paymentmethod: scard}
			axios.post(`${baseUrl}changePackageRequest`, planParams )
            .then(res => {
                if(res?.data?.status === 'success') {
                    setLoading(false)
                    showNotification(
                        <span className='d-flex align-items-center'>
                            <Icon icon='Info' size='lg' className='me-1' />
                            <span>Change Package</span>
                        </span>,
                        res?.data?.message,
                    );
					setInvAmount(res.data.amount);
					setOrderId(res.data.invoice_id);
					setInvId(res.data.order_id);
					setPayStep1(true)
                } else {
                    showNotification(
                        <span className='d-flex align-items-center'>
                            <Icon icon='Info' size='lg' className='me-1' />
                            <span>Change Package Error!</span>
                        </span>,
                        res?.data?.message,
                    );
                    setLoading(false)
					setPayStep1(false)
                }
            }).catch(function (error) {
                setLoading(false)
                if (error.response) {
                    setServerError(`Server status:${error.response.status} status`);
                }
            });
		}
	}

	const updateOrder =()=> {
		
		setLoading(true)
		const planParams = {user_id: user?.user_id, api_token: user?.api_token, order_id: orderId, invoice_id: invId, whmcs_service_id: user?.whmcs_service_id, whmcs_package_id:plid, card_cvv: cvc}
		axios.post(`${baseUrl}payChangePackageInvoice`, planParams )
		.then(res => {
			if(res?.data?.status === 'success') {
				setLoading(false)
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Order Confirm</span>
					</span>,
					res?.data?.message,
				);
				setInvAmount("");
				setOrderId("");
				setInvId("");
				setPayStep1(true)
				setModalStatus2(false)
			} else {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Order Confirm Error!</span>
					</span>,
					res?.data?.message,
				);
				setLoading(false)
			}
		}).catch(function (error) {
			setLoading(false)
			if (error.response) {
				setServerError(`Server status:${error.response.status} status`);
			}
		});
	}

	return (
		<PageWrapper title={extraMenu.accountSettings.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ title: 'Account Settings', to: '/' },
						]}
					/>
					<SubheaderSeparator />
					<span className='text-muted'>{user?.first_name} {user?.last_name}</span>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
                {loading &&
					<Loader />
				}
				<div className='row h-100'>

                    {serverError !== "" ? <div className="alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center" >
                        <span className="text-white">{serverError}</span>
                    </div> : ""}

					<div className='col-xl-3 col-lg-4 col-md-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='Person' iconColor='success'>
									<CardTitle>Account Seetings</CardTitle>
									<CardSubTitle>Account Information</CardSubTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-3'>
									<div className='col-12'>
										<Button
											icon='Settings'
											color='success'
											className='w-100 p-3'
											isLight={TABS.ACCOUNT_SETINGS !== activeTab}
											onClick={() => setActiveTab(TABS.ACCOUNT_SETINGS)}>
											{TABS.ACCOUNT_SETINGS}
										</Button>
									</div>
									<div className='col-12 border-bottom' />
									<div className='col-12'>
										<Button
											icon='FeaturedPlayList'
											color='info'
											className='w-100 p-3'
											isLight={TABS.PACKAGES !== activeTab}
											onClick={() => setActiveTab(TABS.PACKAGES)}>
											{TABS.PACKAGES}
										</Button>
									</div>
									{TABS.PACKAGES === activeTab && (
										<div className='col-12 d-flex align-items-center justify-content-center'>
											<div className='row text-center'>
												<div className='col-12 text-uppercase fw-light mt-5'>
													Per Month
												</div>
												<div className='col-12 text-uppercase h2 fw-bold mb-2'>
													Select Your Perfect Plan
												</div>
												<Icon icon='Verified' size='5x' color='info' />
											</div>
										</div>
									)}
								</div>
							</CardBody>
							<CardFooter>
								<CardFooterLeft className='w-100'>
									<div className='col-12 shadow-3d-container'>
										<Card className="card shadow-sm transition-base rounded-2 mb-0 text-dark bg-l25-secondary bg-l10-secondary-hover">
											<CardHeader className='bg-transparent'>
												<CardLabel>
													<CardTitle>Balance</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody className="pt-0">
												<div className='d-flex align-items-center pb-3'>
													<div className='flex-shrink-0'>
														<Icon
															icon='LocalOffer'
															size='4x'
															color='secondary'
														/>
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-3 mb-0'>
															${calculate2(userData?.credit)}
														</div>
														<div className='text-muted mb-0'>
															Package: {userData?.package_detail?.pkg_name}
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								</CardFooterLeft>
							</CardFooter>
						</Card>
					</div>
					<div className='col-xl-9 col-lg-8 col-md-6'>
						{TABS.ACCOUNT_SETINGS === activeTab && (
                            <Card stretch tag='form' noValidate onSubmit={formik.handleSubmit}> 
                                <CardHeader>
									<CardLabel icon='Settings' iconColor='success'>
										<CardTitle>{TABS.ACCOUNT_SETINGS}</CardTitle>
									</CardLabel>
								</CardHeader>
                                <CardBody> 
                                    <div className='row g-4 mb-4'>
                                        <div className='col-md-3'>
                                            <label className='border-0 bg-transparent cursor-pointer fs-6 fw-bold pt-3' htmlFor='timeZone'>
                                                <Icon icon='StickyNote2' size='2x' color='grey' /> Package Type
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <label style={{paddingTop:20}}>
                                                {userData?.package_detail?.pkg_type === 1 || userData?.package_detail?.pkg_type === "1" ? "Credit" : null}
                                                {userData?.package_detail?.pkg_type === 2 || userData?.package_detail?.pkg_type === "2" ? "Topup" : null}
                                                {userData?.package_detail?.pkg_type === 3 || userData?.package_detail?.pkg_type === "3" ? "Monthly Subscription" : null}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row g-4 mb-4'>
                                        <div className='col-md-3'>
                                            <label className='border-0 bg-transparent cursor-pointer fs-6 fw-bold pt-3' htmlFor='timeZone'>
                                                <Icon icon='Notes' size='2x' color='grey' /> Package
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <label style={{paddingTop:20}}>
                                                {userData?.package_detail?.pkg_name}
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        showTimeZ === false ?
                                        <div className='row g-4'>
                                            <div className='col-md-3'>
                                                <label className='border-0 bg-transparent cursor-pointer fs-6 fw-bold pt-3' htmlFor='timeZone'>
                                                    <Icon icon='AccessTime' size='2x' color='grey' /> Change Time Zone
                                                </label>
                                            </div>
                                            <div className='col-md-6'>
                                                <FormGroup
                                                    id='timeZone'
                                                    label='Select Time Zone'
                                                    isFloating
                                                >
                                                    <Select
                                                        ariaLabel='Select Time Zone'
                                                        placeholder='Select Time Zone'
                                                        list={TimeZone}
                                                        onChange={changeTimeZone}
                                                        value={timeZ}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-md-3'>
                                                <Button
                                                    type='button'
                                                    icon='Save'
                                                    color='info'
                                                    size='lg'
                                                    isOutline
                                                    onClick={updateTimeZone}
                                                />
                                            </div>
                                        </div>
                                        : null
                                    }
                                    {
                                        showTimeZ === true ?
                                        <div className='row g-4'>
                                            <div className='col-md-3'>
                                                <label className='border-0 bg-transparent cursor-pointer fs-6 fw-bold pt-3' htmlFor='timeZone'>
                                                    <Icon icon='AccessTime' size='2x' color='grey' /> Change Time Zone
                                                </label>
                                            </div>
                                            
                                            <div className='col-md-6'>
                                                <label style={{paddingTop:20}}>
                                                    {TimeZone.filter((item)=>item.value === user?.time_zone)[0].text}
                                                </label>
                                            </div>
                                            <div className='col-md-3'>
                                                <Button
                                                    type='button'
                                                    icon='EditNote'
                                                    color='info'
                                                    size='lg'
                                                    isOutline
                                                    onClick={onOffTZEdit}
                                                />
                                            </div>
                                        </div>
                                        : null
                                    }
									{
										showCntr === true ? 
										<div className='row g-4 mt-3'>
											<div className='col-md-3'>
												<label className='border-0 bg-transparent cursor-pointer fs-6 fw-bold' htmlFor='country'>
													<Icon icon='Public' size='2x' color='grey' /> Allowed Countries
												</label>
											</div>
											<div className='col-md-6 account-settings'>
												{
													countriesData !== undefined && countriesData.length > 0 ? 
													<FormGroup
														id='country'
														isFloating
													>
														<Select
															multiple
															ariaLabel='Select Contry'
															placeholder='Selct Country'
															list={countriesData !== undefined && countriesData.length > 0 ? countriesData : []}
															onChange={handleChange}
															selected={allowContries}
															onBlur={formik.handleBlur}
															value={formik.values.country}
															style={{minHeight:250}}
														/>
													</FormGroup>
													: null
												}
												{
													countriesData !== undefined && countriesData.length === 0 ? 
												<label className='pt-3'>No any country allowed</label>
													: null
												}
											</div>
											<div className='col-md-3'>
												<Button
													type='button'
													icon='EditLocationAlt'
													color='info'
													size='lg'
													isOutline
													onClick={hideAC}
												/>
											</div>
										</div>
										: null
									}
									{showCntr2 === true ? 
										<div className='row g-4 mt-3 mb-5'>
											<div className='col-md-3'>
												<label className='border-0 bg-transparent cursor-pointer fs-6 fw-bold' htmlFor='country'>
													<Icon icon='Public' size='2x' color='grey' /> Apply Countries
												</label>
											</div>
											<div className='col-md-6'>
												{
													acData === true ?
													<MultiSelect
														options={allowedCountries}
														value={multiple}
														onChange={setMultiple}
														labelledBy="Select"
													/>
													: <span style={{paddingTop:5, display:`block`}}>No Country Available for Allow</span>
												}
											</div>
											<div className='col-md-3'>
												{
													acData === true ?
													<>
														<Button
															type='button'
															icon='Save'
															color='info'
															size='lg'
															isOutline
															onClick={hideAC2}
														/>
														<Button
															type='button'
															icon='Close'
															color='grey'
															size='lg'
															isOutline
															onClick={hideAC3}
														/>
													</>
													: <Button
														type='button'
														icon='Close'
														color='grey'
														size='lg'
														isOutline
														onClick={hideAC3}
													 />
												}
											</div>
										</div>
									: null}
								</CardBody>
							</Card>
						)}
						{TABS.PACKAGES === activeTab && (
							<div>
								{
									pkgData !== undefined && pkgData.length > 0 ?
									<div className='row g-4 sms-packages'>
										{
											pkgData.map((pkg) => {
												return(
													<div className='col-md-6' key={pkg.id} id={pkg.id}>
														<Card stretch>
															<CardBody>
																{
																	pkg.pkg_name === userData?.package_detail?.pkg_name ? 
																	<span className="position-absolute top-1 end-0 border border-success border-2 text-success fw-bold px-2 py-1 rounded-1 lead text-uppercase fs-6 mr-5 " style={{marginRight:10}}>Active</span>
																	: null
																}
																
																<div className='row pt-5 g-4 text-center'>
																	<div className='col-12'>
																		{
																			pkg.pkg_name === userData?.package_detail?.pkg_name ? 
																			<Icon icon='MapsHomeWork' size='7x' color='success' />
																			: <Icon icon='CustomRocketLaunch' size='7x' color='info' />
																		}
																	</div>
																	<div className='col-12'>
																		<h2>{pkg.pkg_name}</h2>
																	</div>
																	<div className='col-12'>
																		<h3 className='display-1 fw-bold'>
																			<span className='display-4 fw-bold'>$</span>{pkg.minimum_topup}
																			<span className='display-6'>/mo</span>
																			<small className="d-block fs-6">Minimum Deposite</small>
																		</h3>
																		<Button
																			color='info'
																			icon='AttachMoney'
																			isLink
																			data-id={pkg.id}
																			onClick={() => [setModalStatus(!modalStatus), openModal(pkg.id)]}
																		>
																			View Price
																		</Button>
																	</div>
																	<div className='col-12'>
																		<div className='lead'>
																			<Icon icon='Done Outline' color='success' /> Sender IDs: {pkg.sender_ids_limit === -1 ? 'unlimited': pkg.sender_ids_limit}
																		</div>
																		<div className='lead'>
																			<Icon icon='Done Outline' color='success' /> Contacts Limit: {pkg.contact_limit === -1 ? 'unlimited': pkg.contact_limit}
																		</div>
																		<div className='lead' style={{borderBottom:0}}>
																			<Icon icon='Done Outline' color='success' /> Daily Sending Limit: {pkg.daily_limit === -1 ? 'unlimited': pkg.daily_limit}
																		</div>
																	</div>
																	<div className='col-12'>
																		<p style={{marginBottom:0, maxWidth:270, margin:`0 auto`}}>{pkg.description}</p>
																	</div>
																	<div className='col-12'>
																		{
																			pkg.pkg_name === userData.package_detail.pkg_name ? 
																			<Button
																				color='success'
																				className='w-100 py-3 text-uppercase'
																				size='lg'>
																				Activated Package
																			</Button>
																			: 
																			<Button
																				color='info'
																				isLight
																				className='w-100 py-3 text-uppercase'
																				size='lg'
																				data-id={pkg.id}
																				onClick={() => changePlan(pkg.billing_pid)}
																			>
																				Select Plan
																			</Button>
																		}
																	</div>
																</div>
															</CardBody>
														</Card>
													</div>
												)
											})
										}
									</div>
									: <Card
										stretch
										tag='form'
									>
										<CardHeader>
											<CardLabel icon='FeaturedPlayList' iconColor='info'>
											<CardTitle>{TABS.PACKAGES}</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody className='pb-0'>
											<div className='row g-4'>
												<div className='col-lg-12' >
													<div style={{fontSize:24,textAlign:`center`, padding:`30px 20px`,color:`#6c757d`, border: `4px solid #e8edf2`,background:`#f5faff`, fontWeight:700, marginBottom:25}}>
														No Package Available For Your Country
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								}
							</div>
						)}
					</div>
					<Modal isCentered="true" isStaticBackdrop="true" setIsOpen={setModalStatus} isOpen={modalStatus} id='package-prices'>
						<ModalHeader setIsOpen={setModalStatus}>
							<ModalTitle id="example-title">
								Country Networks Prices
							</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<div className='row g-4'>
								<div className='col-md-12'>
									<label className="form-label">Select Country for Network Price</label>
									<FormGroup
										id='country'
										label='Select Country'
										isFloating
									>
										<Select
											ariaLabel='Select Country'
											placeholder='Select Country'
											list={countriesData2}
											onChange={getCountryPrice}
										/>
									</FormGroup>
								</div>
								<div className='col-md-12'>
									{
										showNetworks === true ?
										<Card stretch size="lg" className="mb-0 " borderColor='primary' borderSize={3} shadow='lg' key={networks?.country_prices?.country_id}>
											<CardBody className=" modal-lg">
												<div className='row g-4 align-items-center'>
													<div className='col-auto'>
														<Icon icon='Maps Home Work' size='5x' color='primary' />
													</div>
													<div className='col'>
														<h2>{networks?.country_prices?.country_name}</h2>
													</div>
													<div className='col-12 mt-0'>
														<h3 className='display-3 fw-bold'>
															<span className='display-5 fw-bold'>$</span>{networks?.country_prices?.price > 0 ? networks?.country_prices?.price : networks?.country_prices?.basic_price}
														</h3>
													</div>
													<div className='col-12 mt-0 networks-list'>
													{ networks?.network_prices !== undefined && networks?.network_prices.length > 0 ?
														networks?.network_prices?.map((np) => {
															return(
																<div className='row' key={np.id}>
																	<div className='lead'>
																		<Icon icon='Done Outline' color='success' /> 
																		{np.company_name}
																		<span className='fs-6 fw-bold' style={{float:`right`}}>
																			${np.price > 0 ? np.price : np.basic_price}
																		</span>
																	</div>
																</div>
															)
														})
														: null
													}
													</div>
													
												</div>
											</CardBody>
										</Card> 
										: null
									}
								</div>
							</div>
						</ModalBody>
						{/* <ModalFooter>
							<Button icon='Close' color='info' isLink onClick={() => setModalStatus(false)}>
								Close
							</Button>
						</ModalFooter> */}
					</Modal>
					<Modal
						setIsOpen={setModalStatus2}
						isOpen={modalStatus2}
						titleId='add-new-balance'
						isStaticBackdrop="true"
						isCentered>
						<ModalHeader setIsOpen={setModalStatus2}>
							<ModalTitle id='add-new-balance'>{payStep1 === false ? "Confirm Order" : "Confirm Pay"}</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<div className='row'>
								<div className='col-md-12'>
									<div className='card card-stretch-full shadow-lg border-3 border-dark mb-0'>
										<div className='card-body  modal-lg pb-3'>
											<div className='row g-4'>
												{serverError !== "" ? <div className="alert alert-danger alert-sm mb-4 d-flex justify-content-center align-items-center" >
													<span className="text-white">{serverError}</span>
												</div> : ""}
												{
													payStep1 === true ?
													<div className='col-12'><div className="alert alert-light-info alert-sm mb-0 d-flex justify-content-center align-items-center" >
														<span className="text-info">Invoice has been generated. Confirm pay to update package!</span>
													</div></div>
													: null
												}
												{
													payStep1 === true ?
													<div className='col-6'>
														<Label style={styles.label} htmlFor="creditcard">Invoice ID</Label>
														<h3 className="display-5 fw-bold text-success"><span className="display-6 fw-bold">#</span>{invId}</h3>
													</div>
													: null
												}
												{
													payStep1 === true ?
													<div className='col-6' style={{textAlign:`right`}}>
														<Label style={styles.label} htmlFor="amount" className="text-right">Amount</Label>
														<h3 className="display-5 fw-bold text-info"><span className="display-6 fw-bold">$</span>{invAmount}</h3>
													</div>
													: null
												}
												{
													payStep1 === false ?
													<div className='col-12'>
														<Label style={styles.label} htmlFor="creditcard">Select Credit Card</Label>
														<select className="form-control" ref={ref2} id='creditcard' name='creditcard' value={scard} onChange={changeCard} style={styles.select}>
															<option value="">Select Credit Card</option>
															{cards?.map((bcl) => {
																return(
																	<option key={bcl.id} value={bcl.id}>{bcl.card_type} **** **** **** {bcl.card_last_four} </option>
																)
															})}
														</select>
													</div>
													: null
												}
												{
													payStep1 === false ?
													<div className='col-12'>
														<Label style={styles.label} htmlFor="cardCVV">Card CVC</Label>
														<FormGroup
															id='cardCVV'
															label='Card CVC'
															isFloating>
															<Input
																type="text"
																placeholder='Card CVC'
																ref={ref}
																onChange={changeCVC}
																value={cvc}
															/>
														</FormGroup>
													</div>
													: null
												}
													
											</div>
											<div className="modal-footer mb-0 pb-0 mt-4 pt-3 border-1 d-flex justify-content-between" style={styles.prl0}>
												<Button
													color="info"
													onClick={() => setModalStatus2(false)}
												>
													Cancel
												</Button>
												{
													payStep1 === false ?
													<Button
														color="success"
														onClick={updatePackage}
													>
														Confirm Order
													</Button>
													: null
												}
												{
													payStep1 === true ?
													<Button
														color="success"
														onClick={updateOrder}
													>
														Confirm Pay
													</Button>
													: null
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</ModalBody>
					</Modal>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Settings;
